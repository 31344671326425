import { React, useState, useEffect } from "react";
import "./MDBContentCompStyle.css";
import Img from "./Svg/mapImage.jpg";
// import axios from "axios";
import api from "../../api";
import styled from "styled-components";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { googleMapsApiKey } from "../../lib/config/apiKeys";
export default function LocationCard({ data }) {
  const color = (col) => {
    switch (col) {
      case "Good":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
    }
  };
  const [map, setMap] = useState({
    lat: data.latitude,
    lng: data.longitude,
  });
  const [center, setCenter] = useState({
    lat: data.latitude,
    lng: data.longitude,
  });
  const libraries = ["places", "geometry", "drawing"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });
  useEffect(() => {
    setCenter({
      lat: data.latitude,
      lng: data.longitude,
    });
    setMap({
      lat: data.latitude,
      lng: data.longitude,
    });
  }, [data]);

  return (
    <>
      <div className="LocationCardConatainer gap-3">
        {center?.lat && center?.lng && isLoaded ? (
          <GoogleMap
            mapContainerClassName="mapProperty mapProperty-rounded-corner "
            center={center}
            zoom={7}
            onLoad={(mapInstance) => setMap(mapInstance)}
          >
            <Marker
              position={map}
              // onClick={() => setSelectedLocation(location)} // Show InfoWindow on click
            />
          </GoogleMap>
        ) : (
          <img className="ImageContainer " src={Img} alt="img" />
        )}
        <div className="LocationCardInfo m-1">
          <div className="w-100 d-flex flex-row align-items-center justify-content-center ">
            <div className="TextWrapper w-75">
              <h1 className="Heading">{data?.LocationSteet}</h1>
              <p className="subHeading">{data.house_name || data?.city}</p>
            </div>
            <div className="w-25 d-flex flex-column justify-content-end align-items-end">
              <div className="DocumentChip" style={color("Good")}>
                <p className="DocumentTypeText m-0 text-capitalize fw-medium">
                  {data.status}
                </p>
              </div>
            </div>
          </div>
          <div className="HousInfoWrapper">
            <div className="TextInfoWrapper align-items-center">
              <p className="ZipCodeInfo">PostCode:</p>
              <p className="ZipcodeVal ">{data.ZipCode}</p>
            </div>
            <div className="RoomInfoContainer">
              <div className="IconWrapper">
                <div className="Icon">
                  <svg
                    className="SVGIcon"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.37313 9.35976V1.85976C2.37313 1.7363 2.34881 1.61406 2.30157 1.5C2.25432 1.38595 2.18508 1.28232 2.09778 1.19502C2.01049 1.10773 1.90685 1.03848 1.7928 0.991237C1.67874 0.943993 1.5565 0.919678 1.43305 0.919678C1.30959 0.919678 1.18735 0.943993 1.07329 0.991237C0.959237 1.03848 0.855603 1.10773 0.768309 1.19502C0.681014 1.28232 0.611768 1.38595 0.564525 1.5C0.517282 1.61406 0.492966 1.7363 0.492966 1.85976V14.1401C0.492966 14.2635 0.517282 14.3858 0.564525 14.4998C0.611768 14.6139 0.681014 14.7175 0.768309 14.8048C0.855603 14.8921 0.959237 14.9614 1.07329 15.0086C1.18735 15.0558 1.30959 15.0802 1.43305 15.0802C1.5565 15.0802 1.67874 15.0558 1.7928 15.0086C1.90685 14.9614 2.01049 14.8921 2.09778 14.8048C2.18508 14.7175 2.25432 14.6139 2.30157 14.4998C2.34881 14.3858 2.37313 14.2635 2.37313 14.1401V12.3785L21.6274 12.3338V14.0945C21.6274 14.2179 21.6517 14.3401 21.6989 14.4541C21.7461 14.5682 21.8154 14.6718 21.9026 14.759C21.9899 14.8463 22.0935 14.9155 22.2075 14.9628C22.3216 15.01 22.4438 15.0343 22.5672 15.0343C22.6906 15.0343 22.8128 15.01 22.9269 14.9628C23.0409 14.9155 23.1445 14.8463 23.2318 14.759C23.319 14.6718 23.3883 14.5682 23.4355 14.4541C23.4827 14.3401 23.507 14.2179 23.507 14.0945V9.31512L2.37313 9.35976Z"
                      fill="#2933AA"
                    />
                    <path
                      d="M23.507 8.55478H9.21744V5.0143C9.21744 4.52108 9.41334 4.04805 9.76205 3.69925C10.1108 3.35044 10.5837 3.15442 11.077 3.1543H19.752C20.7479 3.1543 21.703 3.54992 22.4072 4.25412C23.1114 4.95833 23.507 5.91344 23.507 6.90934V8.55478Z"
                      fill="#2933AA"
                    />
                    <path
                      d="M5.87232 8.14957C7.13975 8.14957 8.1672 7.12212 8.1672 5.85469C8.1672 4.58727 7.13975 3.55981 5.87232 3.55981C4.60489 3.55981 3.57744 4.58727 3.57744 5.85469C3.57744 7.12212 4.60489 8.14957 5.87232 8.14957Z"
                      fill="#2933AA"
                    />
                  </svg>
                </div>
                <p className="IconVal">{data.bedroomno || 0}</p>
              </div>
              <div className="IconWrapper">
                <div className="Icon">
                  <svg
                    className="SVGIcon"
                    width="24"
                    height="19"
                    viewBox="0 0 24 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.01371 7.00012C6.25746 6.72399 6.24803 6.1137 5.98399 5.84966C5.70945 5.57507 5.26424 5.57507 4.98965 5.84966L4.64457 6.19474V3.36884C4.64457 2.68392 5.2018 2.28749 5.88673 2.28749C6.57165 2.28749 7.08621 2.68392 7.08621 3.36884C7.08621 3.75715 7.4437 4.07196 7.832 4.07196C8.22031 4.07196 8.53511 3.75715 8.53511 3.36884C8.53511 1.90853 7.832 0.720459 5.88673 0.720459C3.81055 0.720459 3 2.03981 3 3.50012V6.19474L2.89326 5.84966C2.61871 5.57507 2.1735 5.57507 1.89892 5.84966C1.63487 6.1137 1.6255 6.72394 1.8692 7.00012H6.01371ZM23.2969 7.93779H0.703111C0.314806 7.93779 0 8.2526 0 8.6409C0 9.02921 0.314806 9.34402 0.703111 9.34402H0.967622C1.33183 10.7228 1.6967 12.1014 2.06237 13.4798C2.50603 15.152 3.66565 16.4372 5.17031 17.0527L4.52035 17.854C4.27571 18.1556 4.32193 18.5984 4.62347 18.843C4.74849 18.9447 4.90478 19.0002 5.06596 19.0001C5.27057 19.0001 5.47349 18.9112 5.61242 18.7399L6.67876 17.4253C6.85923 17.4431 7.04199 17.4532 7.22728 17.4532H16.7745C16.9616 17.4532 17.1461 17.4429 17.3283 17.4248L18.3951 18.7399C18.461 18.8213 18.5443 18.8869 18.6388 18.932C18.7334 18.977 18.8368 19.0003 18.9415 19.0001C19.0972 19.0001 19.2538 18.9487 19.384 18.843C19.6856 18.5984 19.7318 18.1556 19.4872 17.854L18.8358 17.0511C20.3389 16.4348 21.497 15.1498 21.9399 13.4782C22.305 12.0998 22.6713 10.7218 23.0388 9.34406H23.2968C23.6851 9.34406 23.9999 9.02925 23.9999 8.64095C23.9999 8.25264 23.6852 7.93779 23.2969 7.93779Z"
                      fill="#2933AA"
                    />
                  </svg>
                </div>
                <p className="IconVal">{data.bathroomno || 0}</p>
              </div>
              <div className="IconWrapper">
                <div className="Icon">
                  <svg
                    className="SVGIcon"
                    width="24"
                    height="18"
                    viewBox="0 0 24 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.49991 14L2.77807 16.3993C2.69434 16.7342 2.76814 17.0819 2.98055 17.3539C3.19295 17.626 3.51274 17.7821 3.85761 17.7821C4.36899 17.7821 4.8132 17.4353 4.93714 16.9391L5.79143 14.0114H3.79274C3.69434 14.0114 3.59641 14.0076 3.49991 14ZM20.4352 13.9886C20.3387 13.9962 20.2408 14 20.1424 14H18.1442L19.0629 16.9391C19.1228 17.18 19.2617 17.3939 19.4573 17.5466C19.653 17.6994 19.8942 17.7823 20.1424 17.7821C20.4873 17.7821 20.8071 17.626 21.0195 17.3539C21.2319 17.0819 21.3057 16.7342 21.2219 16.3993L20.4352 13.9886ZM2.20748 4.17243C3.41143 4.27225 4.42616 5.1635 4.66647 6.36414L5.24882 9.27633C5.3141 9.60275 5.60314 9.83975 5.93618 9.83975H18.0637C18.3967 9.83975 18.6858 9.60275 18.751 9.27633L19.3334 6.36414C19.5567 5.24818 20.4493 4.3995 21.5407 4.20507L21.6358 3.67335C21.7985 2.76081 21.5506 1.82982 20.9555 1.1188C20.3604 0.407782 19.4871 0 18.5599 0H5.17833C4.23031 0 3.34473 0.42292 2.74867 1.15996C2.15261 1.89746 1.92459 2.85211 2.12328 3.77884L2.20748 4.17243Z"
                      fill="#2933AA"
                    />
                    <path
                      d="M23.4655 6.03261C23.0922 5.63367 22.5649 5.40485 22.0185 5.40485C21.802 5.40485 21.5922 5.44071 21.395 5.50651L21.3951 5.50609C20.7308 5.7236 20.2081 6.31058 20.0756 6.99771L19.4932 9.90966C19.3573 10.5887 18.7561 11.0816 18.0636 11.0816H5.9364C5.24388 11.0816 4.64266 10.5887 4.50684 9.90966L3.92445 6.99771C3.77837 6.23073 3.14219 5.59464 2.37644 5.44506L2.37649 5.44525C1.7134 5.30456 0.995236 5.53532 0.53447 6.03261C0.161222 6.43159 -0.0320249 6.97301 0.00430652 7.51813L0.267993 11.4731C0.274947 13.101 1.5736 14.4291 3.18983 14.485L3.18964 14.4858C3.22465 14.4872 3.26013 14.4877 3.29561 14.4877H20.7044C20.7399 14.4877 20.7754 14.4872 20.8104 14.4858L20.8102 14.485C22.4264 14.4291 23.7251 13.101 23.732 11.4731L23.9957 7.51813C24.032 6.97301 23.8388 6.43154 23.4655 6.03261Z"
                      fill="#2933AA"
                    />
                  </svg>
                </div>
                <p className="IconVal">{data.halls || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// LocationCard.defaultProps={
//     imgsrc:""
// }

const Container = styled.div`
  padding: 10px;
`;
