import React from 'react';
import styled from 'styled-components';

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f8;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    font-size: 32px;
    color: #21296D;
    margin-bottom: 10px;
`;

const Date = styled.p`
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
`;

const Score = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #21296D;
    margin-bottom: 20px;
`;

const ScoreValue = styled.span`
    font-weight: bold;
    font-size: 36px;
    color: ${(props) => (props.score >= 800 ? '#4CAF50' : '#FF5722')};
    margin-right: 10px;
`;

const Label = styled.span`
    font-size: 18px;
`;

const ScoreCard = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

const ScoreItem = styled.div`
    text-align: center;
`;

const ReportText = styled.p`
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
`;

const CurrentEPCReport = ({data}) => {
    

    return (
        <ReportContainer>
            <Title>EPC Report</Title>
            <Date>Report Date: {data?.report_date}</Date>
            
            <Score>
                <ScoreValue score={data?.score}>{data?.score}</ScoreValue>
                <Label>out of 1000</Label>
            </Score>

            <ScoreCard>
                <ScoreItem>
                    <Label>Current Score</Label>
                    <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#21296D' }}>{data?.current_score}</div>
                </ScoreItem>
                <ScoreItem>
                    <Label>Potential Score</Label>
                    <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#21296D' }}>{data?.potential_score}</div>
                </ScoreItem>
            </ScoreCard>

            <ReportText>{data?.report}</ReportText>
        </ReportContainer>
    );
};

export default CurrentEPCReport;
