import React, { useState, useEffect, useContext } from "react";
import { FormDataContext } from "../../Context/FormDataContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FileSpinner from "../../components/Spinner/FileSpinner";
import { base_path } from "../../config";
import { fAuth } from "../../lib/services/firebase/firebaseAuth";

const DocumentsUploading = () => {
  const { formdata } = useContext(FormDataContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  useEffect(() => {
    if (!formdata) {
      window.alert("Form Data is not available");
      navigate("/property/upload");
      return;
    }

    const upload = async () => {
      try {
        setLoading(true);
        const token = await fAuth.currentUser.getIdToken();
        const response = await axios.post(
          `${base_path}landlord/upload-report/`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // setStatusCode(response.status);
        if (response.status === 201) {
          setStatusCode(response.status);
          // navigate('/property/dashboard');
        }
      } catch (err) {
        setLoading(false);
        navigate("/property/upload");
        console.error("Error while fetching the data", err);
      }
    };

    upload();
  }, [formdata, navigate]);

  return <>{loading && <FileSpinner statusCode={statusCode} />}</>;
};

export default DocumentsUploading;
