import { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../../api";
import { useParams } from "react-router-dom";
import { humanReadableDate } from "../../../lib/helper/global/date";
import { FaCircleCheck, FaRegCircle } from "react-icons/fa6";
import LoadingPage from "../../Spinner/Loader";

const TimelineContainer = styled.div`
  padding: 20px;
  padding-top: 30px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const DateLabel = styled.h3`
  font-size: 17px;
  color: #21296d;
  font-weight: bold;
  margin-bottom: 0px;
  grid-column: span 3;
`;

const Entry = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  padding-left: 20px;
  position: relative;
  min-height: 80px;
`;

const MessageText = styled.div`
  font-size: 16px;
  color: #71717a;
  flex: 1;
  grid-column: span 20;
  font-weight: 600;
`;

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    width: 1px;
    height: ${(props) => (props.last ? "0%" : "100%")};
    background-image: repeating-linear-gradient(
      to bottom,
      #d4d4d8,
      #d4d4d8 2px,
      transparent 6px
    );
  }
`;

const TaskExecutorComponent = styled.div`
  padding: 2px 8px;
  background-color: #edf4ff;
  border: 1px solid #5a74fa;
  border-radius: 8px;
  color: #5a74fa;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-left: 20px;
`;

const NoDataFound = styled.div`
  font-size: 18px;
  color: #8a8a8a;
  text-align: center;
  padding: 20px;
`;

const Timeline = () => {
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const propertyId = useParams()?.propertyId;

  useEffect(() => {
    const fetchMainData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`property/timeline/${propertyId}`);
        setMainData(response.data?.data?.results || []);
      } catch (err) {
        console.log("Error while fetching the data", err);
      } finally {
        setLoading(false);
      }
    };
    fetchMainData();
  }, [propertyId]);

  return (
    <TimelineContainer>
      <LoadingPage loading={loading} />
      {!loading && mainData.length === 0 && (
        <NoDataFound>No Data Found</NoDataFound>
      )}
      {mainData.map((section, index, currArr) => (
        <Entry key={index}>
          <DateLabel>{humanReadableDate(section.date, true)}</DateLabel>
          <DotContainer last={index === currArr.length - 1}>
            {section?.status === "completed" ? (
              <FaCircleCheck
                size={18}
                style={{
                  zIndex: 10,
                  color: "#7999FF",
                  backgroundColor: "#f4f4f5",
                }}
              />
            ) : (
              <FaRegCircle
                size={18}
                style={{
                  zIndex: 10,
                  color: "#7999FF",
                  backgroundColor: "#f4f4f5",
                }}
              />
            )}
          </DotContainer>
          <MessageText>
            <span style={{ fontWeight: "bold" }}>({section?.title}) </span>
            <span>{section.description}</span>
            <TaskExecutorComponent>
              {section.task_executor === "agent" ? "Agent" : "Tenant"}
            </TaskExecutorComponent>
          </MessageText>
        </Entry>
      ))}
    </TimelineContainer>
  );
};

export default Timeline;
