import React from "react";
import "./MDBContentCompStyle.css";
import CardsComp from "./CardsComp";
import LocationCard from "./LocationCard";
import CurrentScoreCard from "./CurrentScoreCard";
import styled from "styled-components";
export default function SectionPropertyScoreDetails({
  locationcard,
  Property,
  Age,
  Score,
  Date,
  Tenancy,
}) {
  return (
    <>
      <Container className="SectionPropertyScoreDetailsContainer">
        <ChildContainer className="ContentSection">
          <LocationCard data={locationcard}></LocationCard>
          <CardsComp prop={Property} age={Age} tenancy={Tenancy}></CardsComp>
        </ChildContainer>
        <CurrentScoreCard score={Score} date={Date}></CurrentScoreCard>
      </Container>
    </>
  );
}

const Container = styled.div``;
const ChildContainer = styled.div`
`;
