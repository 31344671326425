import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./AuthNew/Auth/LoginPage";
import MainLandlord from "./MainLandlord";
import MainAgentRoute from "./Agent/MainAgentRoute";
import SignType from "../src/Agent/AuthNew/Auth/SignType";
import { isAuthenticatedUserType, isAuthenticated } from "./PrivateRoute";
import { MianProvider } from "./Context/MainContext";
import ForgotPasswordPage from "./AuthNew/Auth/ForgotPasswordPage";
import SetPasswordPage from "./AuthNew/Auth/SetPasswordPage";
import PasswordSetSuccessful from "./AuthNew/Auth/PasswordSetSuccessful";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
export default function MainRoute() {
  return (
    <div 
     className="app-container"
    >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/forgotpassword"
          element={
            <MianProvider>
              <ForgotPasswordPage />
            </MianProvider>
          }
        />
        <Route
          path="/setpassword"
          element={
            <MianProvider>
              <SetPasswordPage />
            </MianProvider>
          }
        />
        <Route path="/success" element={<PasswordSetSuccessful />} />
        <Route path="/usertype" element={<SignType />} />
        <Route path="/property/:propertyId/*" element={<MainLandlord />} />
        <Route path="/agent/*" element={<MainAgentRoute />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer />
    </div>
  );
}
