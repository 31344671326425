export const stagesObj = {
  open: { name: "Building Report", color: "#FF3B30" },
  pending: { name: "Arranging Engineer", color: "#FF9500" },
  completed: { name: "Completion Report", color: "#34C759" },
  // completedNew: { name: "Completion Report", color: "#34C759" },
};
export const RepairStatus = ({ data = {} }) => {
  const stages = [
    { name: "Building Report", color: "#FF3B30" },
    { name: "Arranging Engineer", color: "#FF9500" },
    { name: "Arranging Access", color: "#FFCC00" },
    { name: "Completion Report", color: "#34C759" },
  ];

  return (
    <div className="  mb-4">
      <div className="custom-progress-container">
        {Object.entries(stagesObj).map(([key, value], index) => {
          let stage = key == data?.status;

          console.log(stage, "stagestagestage");
          return (
            <div
              key={index}
              className="custom-progress-item"
              style={{ marginTop: !stage ? "24px" : "" }}
            >
              <>
                <span>{stage ? <img src="/Indicator.png"></img> : ""}</span>
                <div
                  className="custom-progress-bar"
                  style={{ backgroundColor: value.color }}
                ></div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p className="custom-progress-text">{value.name}</p>
                </div>
              </>
            </div>
          );
        })}
        {/* {data?.map((item, index) => {
          let stage = stagesObj[item.status];
          let [name1, name2] = stage.name.split(" ");

          // let findStatus=data?.
          return (
            <div key={index} className="custom-progress-item">
              <div
                className="custom-progress-bar"
                style={{ backgroundColor: stage.color }}
              ></div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p className="custom-progress-text">{name1}</p>
                <p className="custom-progress-text">{name2}</p>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};
