import React from "react";
import Tooltip from "../customcomponents/Tooltip";

const DownloadButton = ({ Documenturl,filename }) => {
  const downloadFile = (url, filename="filename.pdf") => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const Button = (
    <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: Documenturl ? "pointer" : "not-allowed" }}
    onClick={Documenturl ? () => downloadFile(Documenturl,filename) : null}
  >
    <path
      d="M10.1019 13.3635L10.1019 3.32935"
      stroke="#21296D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5322 10.9238L10.1022 13.3638L7.67223 10.9238"
      stroke="#21296D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9626 7.27344H14.7401C16.4359 7.27344 17.8101 8.6476 17.8101 10.3443V14.4143C17.8101 16.1059 16.4392 17.4768 14.7476 17.4768H5.46423C3.76839 17.4768 2.39339 16.1018 2.39339 14.4059L2.39339 10.3351C2.39339 8.64427 3.76506 7.27344 5.45589 7.27344L6.24089 7.27344"
      stroke="#21296D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
  if (!Documenturl)  <Tooltip text="No Document Available">{Button}</Tooltip>;
  return Button;
};

export default DownloadButton;
