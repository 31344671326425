import React, { useState, useEffect } from "react";
import "./RegularMaintainens.css";
import "./OpenRepairsSection.css";
import "./InspectionInventory.css";
// import ORTableRow from "./ORTableRow";
import api from "../api";
import RMSHTableRow from "./RMSHTableRow";
import { useParams, useSearchParams } from "react-router-dom";
import { transformDataServiceHistory } from "../lib/helper/property/regularMaintainance";
import UploadMaintainancePdf from "./Property/global/UploadMaintainancePdf";
import LoadingPage from "./Spinner/Loader";
let jsonData = [
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Open",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Open",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Open",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
  {
    service: "Service Details Text",
    date: "12/05/2024",
    status: "Completed",
    description: "Lorem ipsum dolor sit amet consectetur. Et a sed.",
    image: "service_image_url",
  },
];

export default function RMServiceHistoryTable({ columns, name }) {
  const [pageStart, setPageStart] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tableSize, setTableSize] = useState(0);
  const [isdata, setIsData] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = parseInt(searchParams.get("page") || "1");
  const handleOpenCurrentTask = (taskId) => {
    setCurrentTaskId(taskId);
  };
  const handleCloseCurrentTask = () => {
    setCurrentTaskId(-1);
  };
  const params = useParams();
  const propertyId = params?.propertyId;
  const fetchData = async (pageNo = 1) => {
    try {
      setLoadingData(true);
      const response = await api.get(`property/maintenance/${propertyId}`, {
        params: {
          limit: 10,
          page: pageNo,
        },
      });
      const data = response.data.data?.results;

      const transformedData = transformDataServiceHistory(data);

      setTableData(transformedData);
      setTotalCount(response.data.data?.count);
      setTableSize(transformedData.length);
    } catch (err) {
      setIsData("No Data Available");
      console.log("Error while fetching data", err);
      setTableData([]);
    } finally {
      setLoadingData(false);
      setSearchParams({ page: pageNo });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const data = (start, range) => {
    if (range <= 0) {
      return tableData.slice(0, 10);
    } else if (range > tableSize) {
      return tableData.slice(start, tableSize);
    } else {
      return tableData.slice(start, range);
    }
  };

  return (
    <>
      <div className="ORTableContainer" style={{
          overflowY: "scroll",
        }}>
        <table className="ORTable" >
          <thead>
            <tr className="w-100 d-flex flex-row">
              {columns.map((itm, i) => (
                <th key={i} className={`${itm.className} tableCol `}>
                  {itm.Header}
                  {i < 3 && (
                    <>
                      <span className="Icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.1041 11.5625L9.99992 16.6667L4.89575 11.5625"
                            fill="#D4D4D8"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.89567 8.4375L9.99984 3.33333L15.104 8.4375"
                            fill="#D4D4D8"
                          />
                        </svg>
                      </span>
                    </>
                  )}
                </th>
              ))}
              <th className="ActionColumn tableCol">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((itm, i) => (
              <RMSHTableRow
                key={i}
                data={itm}
                column={columns}
                name={name}
                handleOpenCurrentTask={handleOpenCurrentTask}
                id={itm?.id}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="ButtonGroupContainer ORTableBtn d-flex flex-row justify-content-end align-items-center gap-2">
        <button
          disabled={pageNo <= 1}
          onClick={() => {
            fetchData(pageNo - 1);
          }}
          className="BtnPrevious d-flex flex-row justify-content-center align-items-center"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="btnText m-0">Previous</p>
        </button>
        <button
          disabled={pageNo >= Math.ceil(totalCount / 10)}
          onClick={() => {
            fetchData(pageNo + 1);
          }}
          className="BtnNext d-flex flex-row justify-content-center align-items-center"
        >
          <p className="btnText m-0">Next</p>
          <svg
            className="nxtIconSvg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <UploadMaintainancePdf
          handleCloseStartTaskModal={handleCloseCurrentTask}
          selectedTaskId={currentTaskId}
          isOpen={currentTaskId !== -1}
          handleReFetch={fetchData}
        />
        <LoadingPage loading={loadingData} />
      </div>
    </>
  );
}
