export const transformRegularMaintainanceData = (data) => {
  return [
    {
      service: "Heating System Service",
      type: data?.heating_system_service?.task_executor_type,
      dueDate: data.heating_system_service?.output_due_date,
      status: data.heating_system_service?.task_status,
      description: data.heating_system_service?.description,
      taskId: data.heating_system_service?.task_id,
      image: "/heating_system.jpg",
    },
    {
      service: "Drain Unblocker Service",
      type: data.drain_blocker_service?.task_executor_type,
      dueDate: data.drain_blocker_service?.output_due_date,
      status: data.drain_blocker_service?.task_status,
      description: data.drain_blocker_service?.description,
      taskId: data.drain_blocker_service?.task_id,
      image: "/drain_blockage.png",
    },
    {
      service: "Plumbing Services",
      type: data.plumbing_service?.task_executor_type,
      dueDate: data.plumbing_service?.output_due_date,
      status: data.plumbing_service?.task_status,
      description: data.plumbing_service?.description,
      taskId: data.plumbing_service?.task_id,
      image: "/plumbing.jpg",
    },
    {
      service: "Light Bulbs and Batteries Replacement",
      type: data.light_bulb_replace_service?.task_executor_type,
      dueDate: data.light_bulb_replace_service?.output_due_date,
      status: data.light_bulb_replace_service?.task_status,
      description: data.light_bulb_replace_service?.description,
      taskId: data.light_bulb_replace_service?.task_id,
      image: "/lights.jpg",
    },
  ];
};

export const transformDataServiceHistory = (data) => {
  return data.map((item) => ({
    id: item.id, // Keeping the original ID
    service: item.title,
    date: item.completion_date, // Formatting date
    dueDate: item.due_date,
    status: item.status, // Capitalizing status
    description: item.description, // Using original description or placeholder
    image: "service_image_url", // Placeholder for service image URL,
    report: item.report,
    details: item.details,
  }));
};

export const maintainanceStatusOptions = [
  { label: "No Task Found", value: "no_task_found" },
  { label: "Start Task", value: "start_task" },
  { label: "Completed", value: "completed" },
  { label: "No Action Needed", value: "no_action_needed" },
];
