import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams, useNavigate } from "react-router-dom";
import { MianProvider } from "../src/Context/MainContext";
import MainDashBordComp from "./MainDashBord/MainDashBoard";
import MainContentSection from "./MainDashBord/MainDashBordBodyContent/MainContentSection";
import PropertytimeLineBodyContent from "./components/PropertytimeLineBodyContent";
import InspectionInventory from "./components/InspectionInventorySection";
import MouldandHumidityComp from "./components/MouldandHumidityComp";
import TenantProfileSectionComp from "./components/TenantProfileSection";
import OpenRepairsSection from "./components/OpenRepairsSection";
import RegularMaintenance from "./components/RegularMaintainens";
import FloarMapPhotosSection from "./components/FloarMapPhotosSection";
import LivingRoomSection from "./components/LivingRoomSection";
import AgentsProfileSection from "./components/AgentsProfileSection";
import EPCSection from "./components/EPCSection";
import RiskAssessmentSection from "./components/RiskAssessmentSection";
import ApplienceSection from "./components/ApplienceSection";
import OvenSection from "./components/OvenSection";
import HeatingSystem from "./components/HeatingSystem";
import MaintenanceandRepair from "./AgentComponents/MaintenanceandRepair/MaintenanceandRepair";
import ReportMainContent from "./AgentComponents/Report/ReportsMainContent";
import Inspection from "./AgentComponents/Inspection/Inspection";
import ReportEPCTableContainer from "./AgentComponents/Report/ReportEPCTableContainer";
import RTISITableContainer from "./AgentComponents/Report/RTSITableContainer";
import PrivateRoute from "./PrivateRoute";
import { LandlordprivateRoute } from "./PrivateRoute";
import ProfileScreen from "./AuthNew/Auth/Profile";
import OtpScreen from "./AuthNew/Auth/OtpScreen";
import UploadScreen from "./AuthNew/Auth/UploadScreen";
import Invite from "./components/Invite";
import { FormDataProvider } from "./Context/FormDataContext";
import DocumentsUploading from "./AuthNew/Auth/DocumentsUploading";
import AIScoreFix from "./components/AIScoreFix";
import RiskDown from "./components/RiskDown";
import DUploadScreen from "./AuthNew/Auth/DuploadScreen";
import MSDocument from "./AuthNew/Auth/MSDocument";
import ContactSupport from "./Agent/pages/ContactUs/ContactUs";
import ReportECIRTableContainer from "./Agent/AgentComponents/Report/ReportECIRTableContainer";
import PropertyTimeline from "./components/Property/timeline/PropertyTimeline";
import PropertyReportECIRTableContainer from "./components/Property/epcr/PropertyReportECIRTableContainer";
import Profile from "./Agent/pages/Profile/Profile";
export default function MainLandlord() {
  const params=useParams();
  const propertyId=params?.propertyId;
  const navigate = useNavigate();
  useEffect(() => {
    if(!propertyId){
      navigate("/agent/dashboard");
    }
  }, [])
  
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <LandlordprivateRoute
              element={<Navigate to={`/property/${propertyId}/dashboard`} />}
            />
          }
        />
        <Route
          path="signup"
          element={
            <MianProvider>
              <ProfileScreen />
            </MianProvider>
          }
        />
        <Route
          path="upload"
          element={
            <MianProvider>
              <FormDataProvider>
                <UploadScreen />
              </FormDataProvider>
            </MianProvider>
          }
        />
        <Route
          path="verifydocuments"
          element={
            <FormDataProvider>
              <DocumentsUploading />
            </FormDataProvider>
          }
        />
        <Route
          path="verifyotp"
          element={
            <MianProvider>
              <FormDataProvider>
                <OtpScreen />
              </FormDataProvider>
            </MianProvider>
          }
        />
        <Route
          path="reupload"
          element={<LandlordprivateRoute element={<DUploadScreen />} />}
        />
        <Route
          path="dashboard"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <MainContentSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="propertytimeline"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <PropertyTimeline />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/inspections&inventory"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <InspectionInventory />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/mouldandhumidity"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <MouldandHumidityComp />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/tenantsprofile"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <Profile />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/openrepair"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <OpenRepairsSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/regularmaintenance"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <RegularMaintenance />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/updatemaintenance"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <MSDocument />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/floormapandphotos"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <FloarMapPhotosSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/floormapandphotos/:itm"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <LivingRoomSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/agentsprofile"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <AgentsProfileSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/epc"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <EPCSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/riskassessment"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <AIScoreFix />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/riskassessment/download"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <RiskDown />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/appliance"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <ApplienceSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/applience/:itm"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <OvenSection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/heatingsystem"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <AIScoreFix />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/AIScoreFix"
          element={
            <PrivateRoute
              element={
                <MainDashBordComp>
                  <AIScoreFix />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="dashboard/ECIR"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <PropertyReportECIRTableContainer />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="invite"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <Invite />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="maintenance"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <MaintenanceandRepair />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="reports"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <ReportMainContent />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="reports/epc"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <ReportEPCTableContainer />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="reports/tenantself-inspections"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <RTISITableContainer />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="reports/inventory"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <RTISITableContainer />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="reports/check-out"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <RTISITableContainer />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="inspections"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <Inspection />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="messages"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <PropertytimeLineBodyContent />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="notifications"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <PropertytimeLineBodyContent />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="contact-us"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <ContactSupport />
                </MainDashBordComp>
              }
            />
          }
        />
        <Route
          path="/agentsprofile"
          element={
            <LandlordprivateRoute
              element={
                <MainDashBordComp>
                  <AgentsProfileSection />
                </MainDashBordComp>
              }
            />
          }
        />
      </Routes>
    </>
  );
}
