import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserTie, FaUser } from "react-icons/fa"; // Icons for landlord and agent
import "./SignType.css";
const SignTypeScreen = () => {
  const [selectedType, setSelectedType] = useState(null);
  const navigate = useNavigate(); // For programmatic navigation

  const handleSelection = (type) => {
    setSelectedType(type);
    if (type === "landlord") {
      localStorage.setItem("usertype", "landlord");
      navigate("/property/signup");
    } else if (type === "agent") {
      localStorage.setItem("usertype", "agent");
      navigate("/agent/signup");
    }
  };

  return (
    <>
      <div className="Heading">
        <h1 className="HeadingText m-0">User Type</h1>
        <p className="loginHeadingText m-0">
          Please select the correct user type to proceed further
        </p>
      </div>

      <div className="container mt-2">
        <div
          className={`user-box col-12 ${
            selectedType === "landlord" ? "selected" : ""
          }`}
          onClick={() => handleSelection("landlord")}
        >
          <FaUserTie className="icon" />
          <h2>Landlord</h2>
        </div>
        <div
          className={`user-box col-12 mt-3 ${
            selectedType === "agent" ? "selected" : ""
          }`}
          onClick={() => handleSelection("agent")}
        >
          <FaUser className="icon" />
          <h2>Agent</h2>
        </div>
      </div>
    </>
  );
};

export default SignTypeScreen;
