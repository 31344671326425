import { React, useState, useEffect } from "react";
import AllTablesSection from "../../../Agent/AgentComponents/Properties/AllTablesSections";
import ReportEPCTable from "./ReportEPCTable";
import SortModal from "../../../Agent/AgentComponents/Properties/SortModal";
import {
  optionToFilterEpc,
  optionToSortEpc,
} from "../../../Agent/AgentComponents/Report/constant";
import CustomModal from "../../../Agent/AgentComponents/Properties/FilterModal";
import api from "../../../api";
import { useParams } from "react-router-dom";
export default function PropertyReportECIRTableContainer() {
  const [tableData, setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const propertyId = useParams()?.propertyId;
  const [url, setUrl] = useState(`/property/ecir-reports/${propertyId}`);

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const [filterOptions, setFilterOptions] = useState({ current_score: "" });

  function convertDate(dateStr) {
    if(!dateStr) return;
    const [year, month, day] = dateStr.split("-");
    return `${day}/${month}/${year}`;
  }

  const fetchData = async (url = `/property/ecir-reports/${propertyId}`) => {
    try {
      const response = await api.get(url);
      const property = response.data?.data?.results;
      console.log(property, "propertypropertypropertyproperty");
      const epcdata = property.map((data) => ({
        id: data.id,
        PropertiesAdderess: data.property_address||data?.inspection_location,
        Document: data?.certificate_number || "ECIR",
        CurrentRating: data.result,
        Date: convertDate(data.date_of_inspection),
        documenturl: data.document,
      }));
      setTableData(epcdata);
      SetTableSize(epcdata.length);
      setNextUrl(response.data.next);
      setPrevUrl(response.data.previous);
    } catch (err) {
      console.log("Error while fetching data", err);
    }
    // const epcdata = [
    //   {
    //     id: "c5b9b53a-e01f-4a16-93bc-2d1d72a3d0a7",
    //     PropertiesAdderess: "111 Commodore House",
    //     Document: "EPC",
    //     CurrentRating: 2,
    //     Date: convertDate(data.report_date || "27-04-13"),
    //     documenturl: "",
    //   },
    // ];
    // setTableData(epcdata);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(0);
  const data = (start, range) => {
    const datapass = searchData == "" ? tableData : SearchTableData;
    const Size = searchData == "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 10);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };


  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleApplySort = () => {
    let newUrl;
    if (sortOption === "") {
      newUrl = url
        .replace(/(&?ordering=[^&]*)/, "")
        .replace(/(\?&)|(&\?)/, "?");
      newUrl = newUrl.endsWith("?") ? newUrl.slice(0, -1) : newUrl;
    } else if (url.includes("ordering=")) {
      newUrl = url.replace(/(ordering=)[^&]*/, `$1${sortOption}`);
    } else {
      const separator = url.includes("?") ? "&" : "?";
      newUrl = `${url}${separator}ordering=${sortOption}`;
    }
    setUrl(newUrl);
    fetchData(newUrl);
    setSortModalOpen(false);
  };

  const handleApplyFilter = (options) => {
    console.log("options", options);
    setFilterOptions(options);
    const { current_score } = options;
    let currentUrl = new URL(url, window.location.origin); // Use URL object for easier manipulation

    // Update or set the query parameters
    if (current_score) {
      currentUrl.searchParams.set("current_score", current_score);
    } else {
      currentUrl.searchParams.delete("current_score");
    }

    // Fetch data with the updated URL
    const newUrl = currentUrl.toString().replace(window.location.origin, "");
    setUrl(newUrl);
    fetchData(newUrl);
    setFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilterOptions({
      current_score: "",
    });
  };

  return (
    <>
      <div className="ReportSection">
        <div className="Container-fluid p-0">
          <div class="row m-0">
            <div className="col-12 p-0">
              {/* <ReportMaintainanceTimeline/> */}
              <AllTablesSection
                className={"ReportEPCTAbleContent"}
                data={data}
                tableSize={tableSize}
                // setSearchData={setSearchData}
                pageSize={10}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                onPageChange={fetchData}
                setSortModalOpen={setSortModalOpen}
                setFilterModalOpen={setFilterModalOpen}
                url={url}
                setUrl={setUrl}
                totalDataCount={tableSize}
              >
                <ReportEPCTable data={tableData} />
              </AllTablesSection>
            </div>
          </div>
        </div>
      </div>
      {sortModalOpen && (
        <SortModal
          setSortModalOpen={setSortModalOpen}
          sortOption={sortOption}
          setSortOption={setSortOption}
          handleSortChange={handleSortChange}
          handleApplySort={handleApplySort}
          options={optionToSortEpc}
        />
      )}
      {filterModalOpen && (
        <CustomModal
          setFilterModalOpen={setFilterModalOpen}
          handleApplyFilter={handleApplyFilter}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          handleClearFilter={handleClearFilter}
          options={optionToFilterEpc}
        />
      )}
    </>
  );
}
