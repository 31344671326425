export const createUrlParams = (urlData) => {
  let datasize = Object.keys(urlData)?.length;
  const keys = Object.keys(urlData);
  let search = "";
  if (datasize) {
    keys.forEach((key) => {
      if (
        urlData[key] !== null &&
        urlData[key] !== "" &&
        urlData[key] !== undefined
      ) {
        search += `${key}=${urlData[key]}&`;
      }
    });
    return search?.substring(0, search.length - 1);
  }
};
