import dayjs from 'dayjs';

export function humanReadableDate(dateString, showYear = false) {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  const today = new Date();

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Return the formatted date
  return year === today.getFullYear() && !showYear
    ? `${day} ${month}`
    : `${day} ${month} ${year}`;
}

export function convertDateHelper(dateStr) {
  if (!dateStr) return;
  const [year, month, day] = dateStr.split("-");
  return `${day}/${month}/${year}`;
}

export function getOneDayBack(dateString) {
  const date = dayjs(dateString);

  // Check if the date is valid
  if (!date.isValid()) {
    return "Invalid date";
  }

  // Subtract one day and format as needed
  return date.subtract(1, "day").format("YYYY-MM-DD");
}

export function formatDateToDDMMYYYY(dateString) {
  const date = dayjs(dateString);
  
  // Check if the date is valid
  if (!date.isValid()) {
    return 'N/A';
  }

  // Format the date to DD/MM/YYYY
  return date.format('DD/MM/YYYY');
}