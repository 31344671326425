import { React, useState, useEffect } from "react";
import "./Report.css";
// import '../Inspection/Inspection.css';
import AllTablesSection from "../Properties/AllTablesSections";
import MaintainanceTimeline from "../MaintainanceTimeline/MaintainanceTimeline";
import TentSelfInspectionTable from "./TentSelfInspectionTable";
import api from "../../api";
import SortModal from "../Properties/SortModal";
import FilterModal from "../Properties/FilterModal";
import { optionToFilterTSI, optionToSortTSI } from "./constant";
import { formatDateToDDMMYYYY } from "../../../lib/helper/global/date";

export default function RTISITableContainer({ viewCards = false }) {
  const [tableData, setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(0);
  const [inspectionsThisMonth, setInspectionsThisMonth] = useState(0);
  const [pendingInspections, setPendingInspections] = useState(0);
  const [overdueInspections, setOverdueInspections] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [url, setUrl] = useState("agent/tenant-self-inpsections/table/");

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const [filterOptions, setFilterOptions] = useState({ result: "" });

  function ConvertDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  // useEffect(() => {
  //   const fetchData = async (url) => {
  //     try {
  //       const response = await api.get(url);

  //     } catch (err) {
  //       console.log("Error while fetching the data", err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const fetchData = async (url = "agent/tenant-self-inpsections/table/") => {
    try {
      const response = await api.get(url);
      const property = response.data?.data?.results;
      const repairdata = property.map((data) => ({
        id: data.property_id,
        PropertiesAdderess: data.property_address,
        Document: "Self Inspection",
        img: data.tenant_profile_photo,
        Result: data.result,
        CreateBy: data.tenant_name,
        Date: formatDateToDDMMYYYY(data.date),
        Documenturl: data.document,
      }));
      setTableData(repairdata);
      SetTableSize(response?.data?.data?.count);
      const tempScoreData = response.data?.data;
      setInspectionsThisMonth(tempScoreData.inspections_done_this_month);
      setPendingInspections(tempScoreData.pending_inspections);
      setOverdueInspections(tempScoreData.overdue_inspections);
      setNextUrl(response.data.next);
      setPrevUrl(response.data.previous);
    } catch (err) {
      console.log("Error while fetching data", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [filterData, setFilterData] = useState([]);

  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(0);
  const data = (start, range) => {
    const datapass = searchData == "" ? tableData : SearchTableData;
    const Size = searchData == "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 10);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };

  const PTsearchingData = (searchState) => {
    const filteredData = tableData.filter((row) => {
      return (
        row.PropertiesAdderess?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase()) ||
        row.Document?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase()) ||
        row.Result?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase()) ||
        row.CreateBy?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase()) ||
        row.Date?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase())
      );
    });
    return filteredData;
  };

  useEffect(() => {
    setSearchTableData(PTsearchingData(searchData));
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleApplySort = () => {
    let newUrl;
    if (sortOption === "") {
      newUrl = url
        .replace(/(&?ordering=[^&]*)/, "")
        .replace(/(\?&)|(&\?)/, "?");
      newUrl = newUrl.endsWith("?") ? newUrl.slice(0, -1) : newUrl;
    } else if (url.includes("ordering=")) {
      newUrl = url.replace(/(ordering=)[^&]*/, `$1${sortOption}`);
    } else {
      const separator = url.includes("?") ? "&" : "?";
      newUrl = `${url}${separator}ordering=${sortOption}`;
    }
    setUrl(newUrl);
    fetchData(newUrl);
    setSortModalOpen(false);
  };

  const handleApplyFilter = (options) => {
    setFilterOptions(options);
    const { result } = options;
    let currentUrl = new URL(url, window.location.origin); // Use URL object for easier manipulation

    // Update or set the query parameters
    if (result) {
      currentUrl.searchParams.set("result", result);
    } else {
      currentUrl.searchParams.delete("result");
    }

    // Fetch data with the updated URL
    const newUrl = currentUrl.toString().replace(window.location.origin, "");
    setUrl(newUrl);
    fetchData(newUrl);
    setFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilterOptions({
      result: "",
    });
  };

  return (
    <>
      <div className="ReportSection">
        <div className="Container-fluid p-0">
          <div class="row m-0">
            {viewCards ? (
              <>
                <div className="col-4 p-0 pe-2">
                  <div className="InspectionCards d-flex flex-column p-3 gap-3">
                    <h1 className="Heading m-0">Inspection Done This Month</h1>
                    <div className="ContentSection d-flex flex-column justify-content-center align-item-center gap-2">
                      <p className="Value m-0">{inspectionsThisMonth}</p>
                      {/* <p className="Text m-0">Pending</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-4 p-0 ps-1 pe-1">
                  <div className="InspectionCards d-flex flex-column p-3 gap-3">
                    <h1 className="Heading m-0">Pending Inspection</h1>
                    <div className="ContentSection d-flex flex-column justify-content-center align-item-center gap-2">
                      <p className="Value m-0">{pendingInspections}</p>
                      {/* <p className="Text m-0">Pending</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-4 p-0 ps-2">
                  <div className="InspectionCards d-flex flex-column p-3 gap-3">
                    <h1 className="Heading m-0">Over Due Inspection</h1>
                    <div className="ContentSection d-flex flex-column justify-content-center align-item-center gap-2">
                      <p className="Value m-0">{overdueInspections}</p>
                      {/* <p className="Text m-0">Pending</p> */}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="col-12 p-0 py-3">
              <AllTablesSection
                className={"ReportEPCTAbleContent"}
                data={data}
                tableSize={tableSize}
                setSearchData={setSearchData}
                pageSize={10}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                onPageChange={fetchData}
                setSortModalOpen={setSortModalOpen}
                setFilterModalOpen={setFilterModalOpen}
                url={url}
                setUrl={setUrl}
                totalDataCount={tableSize}
              >
                <TentSelfInspectionTable data={tableData} />
              </AllTablesSection>
            </div>
          </div>
        </div>
      </div>
      {sortModalOpen && (
        <SortModal
          setSortModalOpen={setSortModalOpen}
          sortOption={sortOption}
          setSortOption={setSortOption}
          handleSortChange={handleSortChange}
          handleApplySort={handleApplySort}
          options={optionToSortTSI}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          setFilterModalOpen={setFilterModalOpen}
          handleApplyFilter={handleApplyFilter}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          handleClearFilter={handleClearFilter}
          options={optionToFilterTSI}
        />
      )}
    </>
  );
}
