import { React, useContext } from "react";
import "../Common/InspectionInventory.css";
import RadialGauge from "../GuageEle";
import { MainContext } from "../../Context/MainContext";
export default function PropertyTableRow({ data }) {
  const { dispatch } = useContext(MainContext);
  const color = (col) => {
    switch (col.toLowerCase()) {
      case "excellent":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "completed":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "pass":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "a":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "fair":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "pending":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "c":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "good":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "d":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "repair":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "open":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "failed":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "b":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      default:
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
    }
  };
  // src/AgentComponents/Properties/PropertyTableRow.js
  return (
    <>
      <tr className="w-100 d-flex flex-row">
        <td className="RowItm propertyrowitm align-items-start">
          {
            <span
              onClick={() => {
                dispatch({
                  type: "popup",
                  payload: {
                    popupId: data.id,
                    popupStae: true,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >{` ${data.PropertiesAdderess}`}</span>
          }
        </td>
        <td className="RowItm propertyrowitm">
          <span className="ChipTenancy">{` ${data.Tenancy}`}</span>
        </td>
        {/* <td className="RowItm d-flex flex-column justify-content-center align-items-start">
                {data.TimeinProperty?` ${data.TimeinProperty}`:`--/--/----`}
            </td> */}
        <td className="RowItm propertyrowitm propScoreWidth">
          <RadialGauge value={data.PropScore} lable={data.complianceLevel} />
        </td>
        <td className="RowItm propertyrowitm complianceLevelWidth">
          <span
            className="ChipTenancy"
            style={color(data.complianceLevel)}
          >{`${data.complianceLevel}`}</span>
        </td>
        <td className="RowItm propertyrowitm">{`${data.OpenRepairs}`}</td>
        <td className="RowItm propertyrowitm">{` ${data.InspectionsDue}`}</td>
      </tr>
    </>
  );
}
