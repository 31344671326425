import React from "react";
import Tooltip from "../customcomponents/Tooltip";

const ViewButton = ({ Documenturl }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const Button = (
    <svg
      onClick={() => Documenturl && openInNewTab(Documenturl)}
      className="Icon"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: Documenturl ? "pointer" : "not-allowed" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6344 7.54418C11.6344 8.99918 10.4544 10.1783 8.9994 10.1783C7.5444 10.1783 6.36523 8.99918 6.36523 7.54418C6.36523 6.08835 7.5444 4.90918 8.9994 4.90918C10.4544 4.90918 11.6344 6.08835 11.6344 7.54418Z"
        stroke="#21296D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99817 13.629C12.1715 13.629 15.074 11.3473 16.7082 7.54398C15.074 3.74065 12.1715 1.45898 8.99817 1.45898H9.0015C5.82817 1.45898 2.92567 3.74065 1.2915 7.54398C2.92567 11.3473 5.82817 13.629 9.0015 13.629H8.99817Z"
        stroke="#21296D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  if (!Documenturl) <Tooltip text="No Document Available">{Button}</Tooltip>;
  return Button;
};

export default ViewButton;
