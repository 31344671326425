import { React, useState } from "react";
import "./RegularMaintainens.css";
import img from "./svg/mn.png";
import { useNavigate } from "react-router-dom";
import { maintainanceStatusOptions } from "../lib/helper/property/regularMaintainance";
import { toast } from "react-toastify";

export default function MaintenanceSchedularItm({
  id,
  statusval,
  countval,
  title,
  reported,
  description,
  due_date,
  report_photos,
  handleOpenCurrentTask,
}) {
  const status = maintainanceStatusOptions.find(
    (x) => x.value === statusval
  )?.label;
  const navigate = useNavigate();

  const butrender = () => {
    switch (statusval) {
      case "completed":
        return (
          <button className="maintenanceCompletedBtn d-flex flex-row align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                d="M7.24121 10.2082L9.21954 12.1857L13.1745 8.23071"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Completed
          </button>
        );

      case "start_task":
        return (
          <button
            className="maintenanceStartedBtn d-flex flex-row justify-content-center align-items-center"
            onClick={() => {
              if (reported !== "agent") {
                toast.error("This task needed to complete by the tenant");
                return;
              }
              handleOpenCurrentTask(id);
            }}
            disabled={!id}
          >
            Start Now
          </button>
        );

      case "no_task_found":
        return (
          <button
            className="maintenanceNeutralBtn d-flex flex-row align-items-center"
            disabled
          >
            No Task Found
          </button>
        );

      case "no_action_needed":
        return (
          <button
            className="maintenanceNeutralBtn d-flex flex-row align-items-center"
            disabled
          >
            No Action Needed
          </button>
        );

      default:
        return (
          <button
            className="maintenanceStartedBtn d-flex flex-row justify-content-center align-items-center"
            onClick={() => handleOpenCurrentTask(id)}
            disabled
          >
            Start Now
          </button>
        );
    }
  };

  return (
    <>
      <div
        className={`MaintenanceSchedularItmContainer  d-flex flex-row gap-3`}
        style={{
          opacity:
            statusval !== "start_task" && status !== "completed" ? 0.5 : 1,
        }}
      >
        <div className="RMImageContainer d-flex flex-row justify-content-center">
          <img
            className="w-100 h-100 RMImage"
            src={report_photos || img}
            alt={"image_alt"}
          />
          <div className="RMImgDueDateContainer d-flex flex-column justify-content-center p-1">
            <h1 className="heading m-0 ">Due Date</h1>
            <p className="date m-0">{due_date}</p>
          </div>
        </div>
        <div className="RMContentContainer d-flex flex-column  py-2">
          <div className="topContainer d-flex flex-column">
            <h1 className="heading m-0">{title || "N/A"}</h1>
            <div className="chipClass">{reported || "N/A"}</div>
          </div>
          <p className="RMItmText m-0">{description ?? "N/A"}</p>
        </div>
        <div className="RMBtnContainer py-2">{butrender()}</div>
        <div
          className={`verticalLine ${
            status === "Pending" ? "" : "verticalLineComplete"
          } ${status === "Pending" && countval === 1 ? "disableline" : ""}`}
        ></div>
      </div>
    </>
  );
}
