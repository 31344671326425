import React from "react";
import "./dashboard.css";
const ProgressIndicator = ({ label, percentage }) => {
  return (
    <div className="IndicatorLoaderContainer w-100">
      <div className="textContainer">
        <p className="text m-0">{label}</p>
        <div className="IndicatorWrapper">
          <span className="percentage-text">{percentage}%</span>
          <div className="Indicator">
            <div
              className="Indicator-before"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceOverview = ({ repair, maintenance, compilance, inspection }) => {
  return (
    <div className="service-overview">
      <h6>Service Overview</h6>
      <div className="indicators-wrap">
        <ProgressIndicator label="Repairs" percentage={repair} />
        <ProgressIndicator label="Inspections" percentage={inspection} />
        <ProgressIndicator
          label="Regular Maintenance"
          percentage={maintenance}
        />
        <ProgressIndicator label="Compliance" percentage={compilance} />
      </div>
    </div>
  );
};

export default ServiceOverview;
