import React, { useState, useEffect } from "react";
import "./dashboard.css";
import RadialGaugeComp from "../MRadialGaugeComp";
import ServiceOverview from "./ServiceOverview";
import Messages from "./Messages";
import MapSection from "./MapSection";
import TaskHistory from "./TaskHistory";
import api from "../../api";

import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [good, setGood] = useState("");
  const [fair, setFair] = useState("");
  const [excellent, setExcellent] = useState("");
  const [poor, setPoor] = useState("");
  const [veryPoor, setVeryPoor] = useState("");
  const [repair, setRepair] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [score, Setscore] = useState("");
  const [inspection, setInspection] = useState("");
  const [compilance, setCompilance] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("agent/dashboard/");

        if (response.status === 204) {
          window.alert(
            "No properties found. Please upload a new property to continue."
          );
          navigate("/agent/upload");
        } else if (response.status === 201 || 200) {
          setGood(response.data.good_count);
          setFair(response.data.fair_count);
          setExcellent(response.data.excellent_count);
          setPoor(response.data.poor_count);
          setVeryPoor(response.data.very_poor_count);

          Setscore(response.data.property_score);
          setRepair(parseFloat(response.data.repair_count.toFixed(2)));
          setMaintenance(
            parseFloat(response.data.maintenance_count.toFixed(2))
          );
          setCompilance(
            parseFloat(response.data.compliance_percentage.toFixed(2))
          );
          setInspection(parseFloat(response.data.inspection_count.toFixed(2)));
        }
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="section progressbar-container">
        <h1
          className="Heading"
          style={{
            textAlign: "left",
            fontSize: "1.3rem",
          }}
        >
          Over All Score
        </h1>

        <div className="row">
          <div
            className="col-8 d-flex justify-content-center"
            style={{ paddingRight: "10px" }}
          >
            <RadialGaugeComp value={score} title={score} />
          </div>
          <div className="col-4 d-flex flex-column justify-content-center gap-3 ps-3">
            <div className="progressitm w-100 d-flex flex-row align-items-center gap-2">
              <div className="itmdot"></div>
              <p className="itmText m-0">Excellent</p>
              <p className="itmTextVal m-0">{excellent}</p>
            </div>
            <div className="progressitm w-100 d-flex flex-row align-items-center gap-2">
              <div className="itmdot"></div>
              <p className="itmText m-0">Good</p>
              <p className="itmTextVal m-0">{good}</p>
            </div>
            <div className="progressitm w-100 d-flex flex-row align-items-center gap-2">
              <div className="itmdot"></div>
              <p className="itmText m-0">Fair</p>
              <p className="itmTextVal m-0">{fair}</p>
            </div>
            <div className="progressitm w-100 d-flex flex-row align-items-center gap-2">
              <div className="itmdot"></div>
              <p className="itmText m-0">Poor</p>
              <p className="itmTextVal m-0">{poor}</p>
            </div>
            <div className="progressitm w-100 d-flex flex-row align-items-center gap-2">
              <div className="itmdot"></div>
              <p className="itmText m-0">Very Poor</p>
              <p className="itmTextVal m-0">{veryPoor}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section service-overview-container">
        <ServiceOverview
          repair={repair}
          maintenance={maintenance}
          compilance={compilance}
          inspection={inspection}
        />
      </div>
      <div className="section messages-container">
        <Messages />
      </div>
      <div className="section map-container" style={{paddingTop: "10px"}}>
        <MapSection />
      </div>
      <div className="section task-history-container">
        <TaskHistory />
      </div>
    </div>
  );
};

export default Dashboard;
