import React,{useState} from "react";
import '../../OpenRepairsSection.css';
import RadialGaugeComp from "../../RadialGaugeComp";
import styled from "styled-components";
export default function PropertyORSGraphSection({value,heading}){
    const [score,setScore]=useState('');
    
    return(
        <Container >
            {/* <div className="PropertyORSGraphSection d-flex flex-column align-items-center gap-2 p-3"> */}
                {/* <h1 className="Heading">{heading}</h1> */}
                <RadialGaugeComp value={value} title={heading} />
                {/* <SMHBVentilationGraph score={75} title={"Ventilation Score"}/> */}
            {/* </div> */}
        </Container>
    );
}

const Container=styled.div`
    display: flex ;
    align-items: center;
    justify-content:center;
`