import {React,useState,useContext} from "react";
import './MDBContentCompStyle.css';
import UploadButton from "./UploadButton";
import UploadDownloadBtn from "./UploadDownloadBtn";
import { useLocation } from "react-router-dom";
import Header from "../../AgentComponents/Calendar/Header";
import { MainContext } from "../../Context/MainContext";
import { useNavigate } from "react-router-dom";
export default function SubHeaderSection(props){
    const navigate = useNavigate();
    const { DateCal,dispatch } = useContext(MainContext);
    const [currentDate,setCurrentDate]=useState(DateCal);
    const prevmonth=()=>{ 
        setCurrentDate(new Date(currentDate.getFullYear(),currentDate.getMonth()-1,1));
        dispatch({
            type:"date",
            payload:{
                currentValDate: new Date(currentDate.getFullYear(),currentDate.getMonth()-1,1)
            }
        })
      };
      const nextmonth=()=>{
        setCurrentDate(new Date(currentDate.getFullYear(),currentDate.getMonth()+1,1));
        dispatch({
            type:"date",
            payload:{
                currentValDate: new Date(currentDate.getFullYear(),currentDate.getMonth()+1,1)
            }
        })
      }
    const loc = useLocation();
    const mypathArray = [];
    const pathMaker = ()=>{
        loc.pathname.split("/").forEach((itm)=>{
           if(itm!==""){
                mypathArray.push(itm);
           }
        })
    }
    
    const renderbtn = ()=>{
        switch (loc.pathname) {
            case "/agent/dashboard": return <UploadButton label="Create Property"/>;
            case "/agent/dashboard/inspections&inventory": return <UploadDownloadBtn/> ;
            case "/agent/dashboard/riskassessment/download": return <UploadDownloadBtn/> ;
            // case "/agent/reports/epc": return <UploadButton/>;
            // case "/agent/reports/tenantself-inspections": return <UploadButton/>;
            // case "/agent/reports/inventory": return <UploadButton/>;
            // case "/agent/reports/check-out": return <UploadButton/>;
            case '/agent/calendar': return <Header currentDate={currentDate} prevMonth={prevmonth} nextMonth={nextmonth}/> ;
            case "/agent/Calendar" :return <Header 
            currentDate={new Date()}
            prevMonth={()=>{}}
            nextMonth={()=>{}}
            />;
            default:
                break
        }
    }
    return(
        <>
            {
            pathMaker()
            }
            <div className='SectionSubMainHeader'>
                <div className="SectionPathContainer">
                    <div className="PathwrapperSection">
                        {
                        mypathArray.map((itm,i)=>{
                            return (
                                <>
                                    <h5 className={`${i!==mypathArray.length-1?"PathTextInactive":"PathText"} m-0`} 
                                    
                                    onClick={() => {
                                        if (itm === "agent") {
                                          navigate("/agent/dashboard");
                                        }
                                        else if(itm === "Reports"){
                                            navigate("/agent/reports/");
                                        }
                                        else{
                                            navigate(`/agent/${itm}`);
                                        }
                                    }
                                }
                                style={{cursor:"pointer"}}
                                >{itm}</h5>
                                    <h5 className={`${i===mypathArray.length-1?"PathTextSpecialInactive":"PathTextSpecial"} m-0`}>/</h5>
                                </>
                            );
                        })
                        }
                    </div>
                </div>
                {renderbtn()}
            </div>
        </>
    );
}