import { Children, React, useMemo } from "react";
import "./Properties.css";
import PropertyTableContainer from "./PropertyTableContainer";
import PropertyTableRow from "./PropertyTableRow";
export default function PropertyTable({ data, children }) {
  const columns = [
    {
      Header: "Properties Adderess",
      className: "header-1",
      accessor: "document.id",
    },
    {
      Header: "Tenancy",
      className: "header-2",
      accessor: "date",
    },
    {
      Header: "Prop. Score",
      className: "header-3 propScoreWidth",
      accessor: "document.type",
    },
    {
      Header: "Compliance Level",
      className: "header-4 complianceLevelWidth",
      accessor: "document.title",
    },
    {
      Header: "Open Repairs",
      className: "header-5",
      accessor: "cretor.name",
    },
    {
      Header: "Inspections Due",
      className: "header-6",
      accessor: "expiry_date",
    },
  ];
  return (
    <>
      <div className="PropertyTableContainer">
        <div className="table-responsive">
          <PropertyTableContainer
            columns={columns}
            data={data}
            tablename={"propertytable"}
          >
            {/* {children} */}
            <PropertyTableRow data={data} />
          </PropertyTableContainer>
        </div>
      </div>
    </>
  );
}
