import React from 'react'
import './dashboard.css';
import image from './Avatar.png'
import { FaLock } from 'react-icons/fa';
const messagesData=[{
    id:1,
    name:"gangadhar",
    role:"Landlord",
    time:'9:45PM',
    date:'Today',
    message:'Hi eveyront e fajfdsakfk',
    image:'Avatar.png',
},
{
    id: 2,
    name: 'Makenna Culhane',
    role: 'Tenant',
    time: '8:41 PM',
    date: 'Today',
    message: 'Lorem ipsum dolor sit amet consectetu',
    image: '/images/makenna.jpg',
  },
  {
    id: 3,
    name: 'Maria Bator',
    role: 'Contractor',
    time: '10:30 AM',
    date: 'Yesterday',
    message: 'Lorem ipsum dolor sit amet consectetur...',
    image: '/images/maria.jpg',
  },
  {
    id: 4,
    name: 'Anika Korsgaard',
    role: 'Landlord',
    time: '8:41 PM',
    date: '10-08-2024',
    message: 'Lorem ipsum dolor sit amet consectetur...',
    image: '/images/anika.jpg',
  },
  {
    id: 5,
    name: 'Giana Baptista',
    role: 'Tenant',
    time: '9:45 PM',
    date: '08-08-2024',
    message: 'Lorem ipsum dolor sit amet consectetur...',
    image: '/images/giana.jpg',
  },
  {
    id: 6,
    name: 'Maren Lipshutz',
    role: 'Contractor',
    time: '10:30 AM',
    date: '06-08-2024',
    message: 'Lorem ipsum dolor sit amet consectetur...',
    image: '/images/maren.jpg',
  },
]

const Messages = () => {
  return (
    <>
    <div className="messages-container">
      {/* Lock Icon */}
      

      {/* Messages Content */}
      {messagesData.map((message) => (
        <div key={message.id} className="message-item">
          <img
            src={message.image ? message.image : image} // Use default image if not provided
            alt={message.name}
            className="message-avatar"
          />
          <div className="message-content">
            <div className="message-header">
              <div className="message-name-role">
                <span className="message-name">{message.name}</span>
                <span className={`message-role ${message.role.toLowerCase()}`}>
                  {message.role}
                </span>
              </div>
              <div className="message-time-date">
                <span className="message-time">{message.time}</span>
                <span className="message-date">{message.date}</span>
              </div>
            </div>
            <p className="message-text">{message.message}</p>
          </div>
        </div>
      ))}
    </div>
    <div className="lock-overlay">
    <FaLock className="lock-icon" />
    <div className="updated-message">Updated soon</div>
    </div>
    </>
  );
};

export default Messages;