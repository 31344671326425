import { React, useState, useEffect } from "react";
import MaintainanceTimeline from "../MaintainanceTimeline/MaintainanceTimeline";
import AllTablesSection from "../Properties/AllTablesSections";
import MROpenRepairTable from "./MROpenRepairTable";
import api from "../../api";
import SortModal from "../Properties/SortModal";
import { createUrlParams } from "../../../helpers/helper";
import FilterModal from "../Properties/FilterModal";
import {
  optionToFilter,
  optionToSort,
  oRoptionToFilter,
  oRoptionToSort,
} from "./constant";

export default function ORTableContainer() {
  const [tableData, setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [url, setUrl] = useState("agent/open-repairs/");
  // Sorting & filter states
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const filterValues = {
    page: 1,
    limit: 10,
    status: "",
    sort_by: "",
  };
  const [query, setQuery] = useState({ ...filterValues });
  const fetchData = async (url = "agent/open-repairs/") => {
    try {
      const response = await api.get(url);
      const property = response?.data?.data || {};

      console.log(property, "propertypropertypropertyproperty");
      const repairdata = property?.results?.map((data) => ({
        id: data.id,
        PropertiesAdderess: data.property_address,
        Description: data.description,
        RepairStatus: data.status,
      }));
      setTableData(repairdata);
      SetTableSize(repairdata.length);
      setNextUrl(property?.next);
      setPrevUrl(property?.previous);
      setTotalDataCount(property?.count);
    } catch (err) {
      console.log("Error while fetching the data", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [filterData, setFilterData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(0);
  const data = (start, range) => {
    const datapass = searchData == "" ? tableData : SearchTableData;
    const Size = searchData == "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 10);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };
  const PTsearchingData = (searchState) => {
    const filteredData = tableData.filter((row) => {
      return (
        row.PropertiesAdderess?.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.FloorPlan?.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.Description?.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.RepairStatus?.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase())
      );
    });
    return filteredData;
  };

  useEffect(() => {
    setSearchTableData(PTsearchingData(searchData));
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);

  const setSortOption = (value) => {
    setQuery({ ...query, sort_by: value });
  };

  useEffect(() => {
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);

  const handleSortChange = (event) => {
    setSortOption(event?.target?.value || "");
  };

  const handleApplySort = () => {
    let newUrl = url;
    newUrl = `${newUrl}?${createUrlParams(query)}`;
    fetchData(newUrl);
    setSortModalOpen(false);
  };

  const handleApplyFilter = (options) => {
    let newUrl = url;

    newUrl = `${newUrl}?${createUrlParams(query)}`;
    fetchData(newUrl);
  };

  const handleClearFilter = () => {
    setQuery({ ...query, status: "" });
  };

  const setFilterOptions = (name, value) => {
    setQuery({ ...query, [name]: value });
  };
  return (
    <>
      <MaintainanceTimeline />
      <AllTablesSection
        tablename={"propertytable"}
        pageSize={5}
        data={data}
        tableSize={searchData == "" ? tableSize : SearchTableDataSize}
        setSearchData={setSearchData}
        nextUrl={nextUrl}
        prevUrl={prevUrl}
        onPageChange={fetchData}
        setSortModalOpen={setSortModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        url={url}
        setUrl={setUrl}
        totalDataCount={totalDataCount}
      >
        <MROpenRepairTable data={tableData} />
      </AllTablesSection>
      {sortModalOpen && (
        <SortModal
          setSortModalOpen={setSortModalOpen}
          sortOption={query?.sort_by}
          setSortOption={setSortOption}
          handleSortChange={handleSortChange}
          handleApplySort={handleApplySort}
          options={oRoptionToSort}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          setFilterModalOpen={setFilterModalOpen}
          handleApplyFilter={handleApplyFilter}
          filterOptions={query}
          setNewFilterOptions={setFilterOptions}
          handleClearFilter={handleClearFilter}
          options={oRoptionToFilter}
        />
      )}
    </>
  );
}
