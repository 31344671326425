import styled from "styled-components";

const WeatherCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  border-radius: 16px; // Rounded corners
  color: #1d3557; // Darker color for better readability

  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); // Soft shadow for elevation
  overflow: hidden;
  font-family: Urbanist, sans-serif;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(173, 216, 230, 0.5);
  z-index: 1;
  border-radius: 16px;
`;

const Heading = styled.h1`
  margin: 0;
  font-size: 1.2rem;
  z-index: 2;
  `;

const Temp = styled.p`
  margin: 0;
  font-size: 2.5rem;
  font-weight: 600;
  z-index: 2;
`;

const Condition = styled.p`
  margin: 0;
  font-size: 1rem;
  z-index: 2;
  font-weight: 500;
`;

const HighLow = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  z-index: 2;
  font-weight: 500;
`;

const WeatherCardComponent = ({ mainData }) => {
  const backgroundImage = mainData?.current_weather?.condition_icon;

  return (
    <WeatherCard backgroundImage={backgroundImage}>
      <Overlay />
      <Heading>{mainData?.current_weather?.location}</Heading>
      <Temp>{mainData?.current_weather?.temperature}°</Temp>
      <Condition>{mainData?.current_weather?.condition}</Condition>
      <HighLow>
        <p>H:{mainData?.current_weather?.max_temp}°</p>
        <p>L:{mainData?.current_weather?.min_temp}°</p>
      </HighLow>
    </WeatherCard>
  );
};

export default WeatherCardComponent;
