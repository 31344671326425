import { React, useState, useEffect } from "react";
import MainTenanceSchedule from "../MaintainanceTimeline/MainTenanceSchedule";
import AllTablesSection from "../Properties/AllTablesSections";
import RegularMaintenanceTable from "./RegularMaintenanceTable";
import api from "../../api";
import SortModal from "../Properties/SortModal";
// import { optionToSort } from "../Properties/constant";
import FilterModal from "../Properties/FilterModal";
import { createUrlParams } from "../../../helpers/helper";
import { optionToFilter, optionToSort } from "./constant";

export default function RMTableContainer({ tdata }) {
  const [tableData, setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [url, setUrl] = useState("agent/regular-maintenance/");
  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(0);
  // Sorting & filter states
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState();

  const filterValues = {
    page: 1,
    limit: 10,
    status: "",
    sort_by: "",
  };
  const [query, setQuery] = useState({ ...filterValues });

  const fetchData = async (url = "agent/regular-maintenance/") => {
    try {
      const response = await api.get(url);
      const property = response?.data?.data || {};
      const repairdata = property?.results?.map((data) => ({
        id: data.property_id,
        PropertiesAdderess: data.property_address,
        Title: data.title,
        Description: data.description,
        Occupancy: data.occupancy,
        Status: data.status,
      }));
      setTableData(repairdata);
      SetTableSize(repairdata?.length);
      setNextUrl(property?.next);
      setPrevUrl(property?.previous);
      setTotalDataCount(property?.count);
    } catch (err) {
      console.log("Error while fetching data", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = (start, range) => {
    const datapass = searchData == "" ? tableData : SearchTableData;
    const Size = searchData == "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 10);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };

  const setSortOption = (value) => {
    setQuery({ ...query, sort_by: value });
  };

  useEffect(() => {
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);

  const handleSortChange = (event) => {
    setSortOption(event?.target?.value || "");
  };

  const handleApplySort = () => {
    let newUrl = url;
    newUrl = `${newUrl}?${createUrlParams(query)}`;
    fetchData(newUrl);
    setSortModalOpen(false);
  };

  const handleApplyFilter = (options) => {
    let newUrl = url;

    newUrl = `${newUrl}?${createUrlParams(query)}`;
    fetchData(newUrl);
  };

  const handleClearFilter = () => {
    setQuery({ ...query, status: "" });
  };

  const setFilterOptions = (name, value) => {
    setQuery({ ...query, [name]: value });
  };
  return (
    <>
      <MainTenanceSchedule />
      <AllTablesSection
        tablename={"maintenancetable"}
        pageSize={tableSize}
        data={data}
        tableSize={searchData == "" ? tableSize : SearchTableDataSize}
        setSearchData={setSearchData}
        nextUrl={nextUrl}
        prevUrl={prevUrl}
        onPageChange={fetchData}
        setSortModalOpen={setSortModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        url={url}
        setUrl={setUrl}
        totalDataCount={totalDataCount}
      >
        <RegularMaintenanceTable data={tableData} />
      </AllTablesSection>
      {sortModalOpen && (
        <SortModal
          setSortModalOpen={setSortModalOpen}
          sortOption={query?.sort_by}
          setSortOption={setSortOption}
          handleSortChange={handleSortChange}
          handleApplySort={handleApplySort}
          options={optionToSort}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          setFilterModalOpen={setFilterModalOpen}
          handleApplyFilter={handleApplyFilter}
          filterOptions={query}
          setNewFilterOptions={setFilterOptions}
          handleClearFilter={handleClearFilter}
          options={optionToFilter}
        />
      )}
    </>
  );
}
