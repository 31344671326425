import { EmailAuthProvider, getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { signInWithRedirect } from 'firebase/auth'
import { signOut } from 'firebase/auth'
import { fClient } from './firebaseApp'

export const fAuthProvider = new EmailAuthProvider()
export const fAuth = getAuth(fClient)
setPersistence(fAuth, browserLocalPersistence)
export const fSignInWithGoogle = () => signInWithRedirect(fAuth, fAuthProvider)
export const fSignOut = () => {
  signOut(fAuth)
}
