import React, { useState, useContext, useEffect } from "react";
import "./Calendar.css";
import image from "./Tap.svg";
import { MainContext } from "../../Context/MainContext";
import { useNavigate } from "react-router-dom";
import api from "../../api";
const Calendar = () => {
  const navigate = useNavigate();
  const { DateCal } = useContext(MainContext);
  const [currentDate, setCurrentDate] = useState(DateCal);
  const [events, setEvents] = useState({});
  useEffect(() => {
    setCurrentDate(DateCal);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `agent/calender/?year=${DateCal.getFullYear()}&month=${
            DateCal.getMonth() + 1
          }`
        );

        const updateEvents = (apiData) => {
          const updatedEvents = { ...events };

          for (const [date, eventData] of Object.entries(apiData)) {
            const limitedEventData = eventData.slice(0, 3);
            updatedEvents[date] = limitedEventData.map((event, index) => {
              const [count, title] = event.split(" ");
              return {
                title: `${count} ${title}`,
                type: index === 0 ? "blue" : index === 1 ? "pink" : "green",
              };
            });
          }

          setEvents(updatedEvents);
        };

        updateEvents(response.data);
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    fetchData();
  }, [DateCal]);

  const Days = () => {
    const daysofWeek = ["MON", "TUE", "WEB", "THU", "FRI", "SAT", "SUN"];
    return (
      <div className="calendar-days-row">
        {daysofWeek.map((day, index) => (
          <div className="calendar-day-name">{day}</div>
        ))}
      </div>
    );
  };
  const handleclickEvent = (type) => {
    if (type === "blue" || type === "green") {
      navigate("/agent/maintenance");
    } else {
      navigate("/agent/reports/tenantself-inspections");
    }
  };
  const cells = () => {
    const monthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    const startDate = monthStart.getDay() === 0 ? -6 : 1 - monthStart.getDay();
    let day = startDate;

    const rows = [];

    for (let i = 0; i < 42; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );

      const formattedDate = date.getDate();

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const dayOfMonth = date.getDate().toString().padStart(2, "0");
      const dateString = `${year}-${month}-${dayOfMonth}`;

      const isCurrentMonth = date.getMonth() === currentDate.getMonth();

      const dayClasses = `col cell ${!isCurrentMonth ? "disabled" : ""}`;

      rows.push(
        <div className={dayClasses} key={i}>
          <span className="number">{formattedDate}</span>
          {events[dateString] &&
            events[dateString].map((event, idx) => (
              <div
                key={idx}
                className={`event ${event.type}`}
                onClick={() => {
                  handleclickEvent(event.type);
                }}
              >
                <img src={image} />
                {event.title}
              </div>
            ))}
        </div>
      );
      day++;
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="CardsContainerele">
      <div className="HouseInfoCardContainer">
        {/* {Header()} */}
        {/* <Header /> */}
        {/* <Header currentDate={currentDate} prevMonth={prevmonth} nextMonth={nextmonth}/> */}
        {Days()}
        {cells()}
      </div>
    </div>
  );
};

export default Calendar;
