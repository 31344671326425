export const optionToSort = [
  { value: "address", label: "Properties Address" },
  { value: "score", label: "Prop. Score" },
  { value: "status", label: "Compliance Level" },
  { value: "open_repair_count", label: "Open Repair Count" },
];

export const optionToFilter = [
  {
    name: "property_score",
    label: "Property Score",
    placeholder: "Select Property Score",
    options: [
      { value: "800-1000", label: "800-1000" },
      { value: "600-800", label: "600-800" },
      { value: "<600", label: "<600" },
    ],
  },
  {
    name: "compliance_level",
    label: "Compliance Level",
    placeholder: "Select Compliance Level",
    options: [
      { value: "Excellent", label: "Excellent" },
      { value: "Fair", label: "Fair" },
    ],
  },
  {
    name: "open_repairs",
    label: "Open Repairs",
    placeholder: "Select Open Repairs",
    options: [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: ">4", label: ">4" },
    ],
  },
];
