import { React, useState, useContext } from "react";
import "./MDBContentCompStyle.css";
import UploadButton from "./UploadButton";
import UploadDownloadBtn from "./UploadDownloadBtn";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../AgentComponents/Calendar/Header";
import { MainContext } from "../../Context/MainContext";
import UploadECIRDOCButton from "../../components/Property/global/UploadECIRDOCButton";
export default function SubHeaderSection(props) {
  const { DateCal, dispatch } = useContext(MainContext);
  const [currentDate, setCurrentDate] = useState(DateCal);
  const params = useParams();
  const propertyId = params.propertyId;
  const navigate = useNavigate();
  const prevmonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
    dispatch({
      type: "date",
      payload: {
        currentValDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        ),
      },
    });
  };
  const nextmonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
    dispatch({
      type: "date",
      payload: {
        currentValDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        ),
      },
    });
  };
  const loc = useLocation();
  const mypathArray = [];
  const pathMaker = () => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    loc.pathname.split("/").forEach((itm) => {
      if (itm !== "" && !uuidRegex.test(itm)) {
        mypathArray.push(itm);
      }
    });
  };

  const renderbtn = () => {
    switch (loc.pathname) {
      // case `/property/${propertyId}/dashboard`:
      //   return <UploadButton />;
      // case `/property/${propertyId}/dashboard/inspections&inventory`:
      //   return <UploadDownloadBtn />;
      case `/property/${propertyId}/dashboard/riskassessment/download`:
        return <UploadDownloadBtn />;
      case `/property/${propertyId}/reports/epc`:
        return <UploadButton />;
      case `/property/${propertyId}/reports/tenantself-inspections`:
        return <UploadButton />;
      case `/property/${propertyId}/reports/inventory`:
        return <UploadButton />;
      case `/property/${propertyId}/reports/check-out`:
        return <UploadButton />;
      case `/property/${propertyId}/dashboard/ECIR`:
        return <UploadECIRDOCButton />;
      // case '/calendar': return <Header currentDate={currentDate} prevMonth={prevmonth} nextMonth={nextmonth}/> ;
      case "/agent/dashboard":
        return <UploadButton />;
      // case "/agent/Calendar" :return <Header
      // currentDate={new Date()}
      // prevMonth={()=>{}}
      // nextMonth={()=>{}}
      // />;
      default:
        break;
    }
  };

  const wordMapping = {
    dashboard: "Dashboard",
    property: "Property",
    propertytimeline: "Property Timeline",
    regularmaintenance: "Regular Maintenance",
    "inspections&inventory": "Inspections & Inventory",
    agentsprofile: "Agents profile",
    riskassessment: "Risk Assessment",
    mouldandhumidity: "Mould And humidity",
    heatingsystem: "heating System",
    floormapandphotos: "Floor Map and Photos",
    tenantsprofile: "Tenants Profile",
    AIScoreFix: "AI Score Fix",
    openrepair: "Open Repair",
  };

  return (
    <>
      {pathMaker()}
      <div className="SectionSubMainHeader">
        <div className="SectionPathContainer">
          <div className="PathwrapperSection">
            {mypathArray.map((itm, i) => {
              return (
                <>
                  <h5
                    className={`${
                      i !== mypathArray.length - 1
                        ? "PathTextInactive"
                        : "PathText"
                    } m-0`}
                    onClick={() => {
                      if (itm === "floormapandphotos") {
                        navigate(
                          `/property/${propertyId}/dashboard/floormapandphotos`
                        );
                      } else if (itm === "riskassessment") {
                        navigate(
                          `/property/${propertyId}/dashboard/riskassessment`
                        );
                      } else if (itm === "mouldandhumidity") {
                        navigate(
                          `/property/${propertyId}/dashboard/mouldandhumidity`
                        );
                      } else if (itm == "property") {
                        navigate(`/property/${propertyId}/dashboard`);
                      } else if (itm == propertyId) {
                        navigate(`/property/${propertyId}/dashboard`);
                      } else if (itm == "dashboard") {
                        navigate(`/property/${propertyId}/dashboard`);
                      } else {
                        if (mypathArray.includes("dashboard")) {
                          if (mypathArray.includes("floormapandphotos")) {
                            navigate(
                              `/property/${propertyId}/dashboard/floormapandphotos/${itm}`
                            );
                          } else {
                            navigate(
                              `/property/${propertyId}/dashboard/${itm}`
                            );
                          }
                        } else {
                          navigate(`/property/${propertyId}/${itm}`);
                        }
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {/* {decodeURIComponent(itm)} */}
                    {wordMapping[itm] || decodeURIComponent(itm)}
                  </h5>
                  <h5
                    className={`${
                      i === mypathArray.length - 1
                        ? "PathTextSpecialInactive"
                        : "PathTextSpecial"
                    } m-0`}
                  >
                    /
                  </h5>
                </>
              );
            })}
          </div>
        </div>
        {renderbtn()}
      </div>
    </>
  );
}
