import React from "react";

const SortModal = ({
  setSortModalOpen = () => {},
  sortOption = "",
  setSortOption = () => {},
  handleSortChange = () => {},
  handleApplySort = () => {},
  options = [],
}) => {
  return (
    <>
      <div className="modal-backdrop fade show custom-backdrop"></div>
      <div className="modal fade show d-block custom-modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Sort Properties</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setSortModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <p className="description">Select a sorting option:</p>
              {options?.map((option) => (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sortOption"
                    value={option?.value}
                    checked={sortOption === option?.value}
                    onChange={handleSortChange}
                    id={option?.value}
                  />
                  <label className="form-check-label" htmlFor={option?.value}>
                    {option?.label}
                  </label>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setSortModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-warning"
                onClick={() => setSortOption("")}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleApplySort}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SortModal;
