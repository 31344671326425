import { React, useMemo } from "react";
import "./MaintenanceandRepair.css";
import PropertyTableContainer from "../Properties/PropertyTableContainer";
import RMTableRows from "./RMTableRows";
export default function RegularMaintenanceTable({ data }) {
  const columns = useMemo(
    () => [
      {
        Header: "Properties Address",
        className: "RMheader-1",
        accessor: "document.id",
      },
      {
        Header: "Description",
        className: "RMheader-2",
        accessor: "expiry_date",
      },
      {
        Header: "Title",
        className: "RMheader-3",
        accessor: "expiry_date",
      },
      {
        Header: "Status",
        className: "RMheader-4",
        accessor: "document.status",
      },
    ],
    []
  );
  return (
    <>
      <div className="RegularMaintenanceTableContainer">
        <PropertyTableContainer
          columns={columns}
          data={data}
          tablename={"RMTable"}
        >
          <RMTableRows data={data} />
        </PropertyTableContainer>
      </div>
    </>
  );
}
