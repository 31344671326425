import React from "react";

const CustomModal = ({
  setFilterModalOpen,
  handleApplyFilter,
  filterOptions,
  setFilterOptions = () => {},
  setNewFilterOptions = () => {},
  handleClearFilter,
  options,
}) => {
  const handleChange = (name, value) => {
    setNewFilterOptions(name, value);
    setFilterOptions(
      (prev) => (
        {
          ...prev,
          [name]: value,
        }
      )
    );
    console.log("abc",(
      {
        [name]: value,
      }
    ))
  };


  const applyChanges = () => {
    handleApplyFilter(filterOptions);
    setFilterModalOpen(false);
  };

  const clearFields = () => {
    handleClearFilter();
  };

  return (
    <>
      <div className="modal-backdrop fade show custom-backdrop"></div>
      <div className="modal fade show d-block custom-modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Filter Properties</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setFilterModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <p className="description">Select options:</p>

              {options?.map((field, index) => (
                <div className="form-group mb-3" key={index}>
                  <label className="form-label">{field.label}</label>
                  <select
                    className="form-select"
                    value={filterOptions[field.name]}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                  >
                    <option value="">{field.placeholder}</option>
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setFilterModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-warning"
                onClick={clearFields}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={applyChanges}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
