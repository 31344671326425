export const optionToSortEpc = [
  { value: "property__address", label: "Properties Address" },
  { value: "current_score", label: "Current Rating" },
  { value: "report_date", label: "Date" },
];

export const optionToFilterEpc = [
  {
    name: "current_score",
    label: "Current Score",
    placeholder: "Select Current Score",
    options: [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
      { value: "E", label: "E" },
      { value: "F", label: "F" },
      { value: "G", label: "G" },
    ],
  },
];

export const optionToSortInventory = [
  { value: "property__address", label: "Properties Address" },
  { value: "result", label: "Result" },
  { value: "expiry_date", label: "Date" },
];

export const optionToFilterInventory = [
  {
    name: "result",
    label: "Result",
    placeholder: "Select Result",
    options: [
      { value: "processing", label: "Processing" },
      { value: "repair", label: "Repair" },
      { value: "fair", label: "Fair" },
      { value: "good", label: "Good" },
      { value: "excellent", label: "Excellent" },
    ],
  },
];

export const optionToSortTSI = [
  { value: "property__address", label: "Properties Address" },
  { value: "result", label: "Result" },
  { value: "expiry_date", label: "Date" },
];

export const optionToFilterTSI = [
  {
    name: "result",
    label: "Result",
    placeholder: "Select Result",
    options: [
      { value: "processing", label: "Processing" },
      { value: "repair", label: "Repair" },
      { value: "fair", label: "Fair" },
      { value: "good", label: "Good" },
      { value: "excellent", label: "Excellent" },
    ],
  },
];

export const optionToSortGasSafety = [
  { value: "property__address", label: "Properties Address" },
  { value: "result", label: "Result" },
  { value: "expiry_date", label: "Date" },
];

export const optionToFilterGasSafety = [
  {
    name: "result",
    label: "Result",
    placeholder: "Select Result",
    options: [
      { value: "processing", label: "Processing" },
      { value: "pass", label: "Pass" },
      { value: "failed", label: "Failed" },
    ],
  },
];
