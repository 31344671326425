import React, { useState } from "react";
import styled from "styled-components";
import { FaSearch, FaTimes } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";

const Form = styled.form`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 350px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 2rem;
  font-size: 1rem;
  border-radius: 12px;
  border: 1px solid #d4d4d8;

  &::placeholder {
    font-size: 0.8rem;
  }
  &:focus {
    outline: none;
    border-color: #d4d4d8;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #a0a0a0;
`;

const SearchIcon = styled(IconWrapper)`
  left: 0.5rem;
`;

const ClearIcon = styled(IconWrapper)`
  right: 0.5rem;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  list-style: none;
  margin: 0;
  padding: 5px 2px;
  padding-bottom: 10px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  max-width: 350px;
  border-radius: 10px;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const MapAutocomplete = ({ data = [], onLocationSelect }) => {
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle search submission
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (!value) {
      setSuggestions(data);
      return;
    }

    // Filter suggestions based on multiple fields
    const filteredSuggestions = data.filter((item) =>
      [item.address, item.house_name, item.zip_code, item.tenant_name].some(
        (field) => field?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );

    setSuggestions(filteredSuggestions);
  };

  const handleSelect = (suggestion) => {
    setSearch(
      suggestion.address || suggestion.house_name || suggestion.zip_code
    );
    onLocationSelect(suggestion);
    setSuggestions([]); // Clear suggestions on selection
  };

  const clearSearch = () => {
    setSearch("");
    setSuggestions([]);
    onLocationSelect()
  };

  return (
    <Form onSubmit={handleSearch}>
      <InputWrapper>
        <SearchIcon>
          <CiSearch size={20} fontWeight={900} />
        </SearchIcon>
        <Input
          type="text"
          placeholder="Search Property"
          value={search}
          onChange={handleChange}
          onBlur={() => setTimeout(() => setSuggestions([]), 300)}
          onFocus={handleChange}
        />
        {search && (
          <ClearIcon onClick={clearSearch}>
            <FaTimes />
          </ClearIcon>
        )}
      </InputWrapper>
      {suggestions.length > 0 && (
        <Dropdown>
          {suggestions.map((suggestion, index) => (
            <DropdownItem key={index} onClick={() => handleSelect(suggestion)}>
              {suggestion.address}{" "}
              {suggestion.house_name ? `, ${suggestion.house_name}` : ""} (
              {suggestion.zip_code})
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Form>
  );
};

export default MapAutocomplete;
