import { useState } from "react";

export const BottomBar = ({ data = {} }) => {
  const [isOpenText, setIsOpenText] = useState(false);
  return (
    <nav
      className="bg-white"
      // style={{ position: "fixed", bottom: "0px", margin: "10px" }}
    >
      <div className="d-flex align-items-center px-4 py-3">
        <div
          className="d-flex gap-2"
          style={{ width: "100%", fontWeight: "bold" }}
        >
          <div className="w-50 d-flex flex-column gap-1">
            <span className="fs-4">Full Report</span>
            <p className="text-secondary">{data?.description}</p>
          </div>
          <div className="w-50 d-flex flex-column gap-1">
            <span className="fs-4">Notes</span>
            <p className="text-secondary">{data?.title}</p>
          </div>
          {/* <div
            className="ms-auto"
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpenText(!isOpenText)}
          >
            <img src="/PlusIcon.jpg" />
          </div> */}
        </div>
      </div>
      {isOpenText ? (
        <input
          type="text"
          className="form-control"
          placeholder="Enter your text here"
        />
      ) : (
        ""
      )}

      <style jsx>{`
        nav {
          border-bottom: 1px solid #f3f4f6;
        }
        .fs-4 {
          font-size: 20px;
        }
      `}</style>
    </nav>
  );
};
