import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Invite.css";
import api from "../api";
import Spinner from "./Spinner/Spinner";
import { toast } from "react-toastify";
const Invite = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const propertyId = useParams()?.propertyId;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("accounts/request", {
        name: name,
        email: email,
        property_id: propertyId,
      });
      if (response.status == 200) {
        setLoading(false);
        navigate(`/property/${propertyId}/dashboard`);
        toast.success("Invitation sent successfully");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        console.log(error.response);
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="InviteContainer w-100 d-flex flex-column justify-content-start align-items-center ">
        <h2 className="Heading m-0 mb-5">Invite Tenant</h2>
        <form
          className="d-flex flex-column w-50 gap-3 mt-5"
          onSubmit={handleSubmit}
        >
          <div className="NameContainer d-flex flex-column gap-1 align-items-center">
            <label htmlFor="name" className="lableText text-start w-75">
              Name:
            </label>
            <input
              className="w-75 inputfeild"
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="NameContainer d-flex flex-column gap-1 align-items-center">
            <label htmlFor="email text-start" className="text-start w-75">
              Email:
            </label>
            <input
              className="w-75 inputfeild"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit" className="submitBtn text-center">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Invite;
