import { React, useCallback, useState, useRef, useEffect } from "react";
import "./UploadScreen.css";
import "./ProfileStyle.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { base_path } from "../../config";
import { useLocation } from "react-router-dom";
import { fAuth } from "../../lib/services/firebase/firebaseAuth";

export default function MSDocument() {
  const location = useLocation();
  const navigate = useNavigate();

  const maintenanceId = location.state?.maintenanceId;
  useEffect(() => {
    if (!maintenanceId) {
      setTimeout(() => {
        navigate("/property/dashboard/regularmaintenance");
      }, 1000);
    }
  }, [maintenanceId, navigate]);
  const [files, setFiles] = useState({
    inventoryReport: null,
    epcReport: null,
    inspectionReport: null,
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState({
    inventoryReport: 0,
    epcReport: 0,
    inspectionReport: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const inventoryRef = useRef(null);

  const handleBrowseClick = (ref) => {
    ref.current.click();
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles((prev) => ({
      ...prev,
      [name]: files[0],
    }));
  };

  const handleUnselect = (key) => {
    setFiles((prev) => ({
      ...prev,
      [key]: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.inventoryReport) {
      window.alert("Please upload the document");
      return;
    }

    setLoading(true);
    let jsonObj = {};
    const formData = new FormData();
    Object.entries(files).forEach(([key, file]) => {
      if (file) {
        jsonObj = {
          ...jsonObj,
          report: file.name,
        };
      }
    });
    formData.append(
      "jsonObj",
      new Blob([JSON.stringify(jsonObj)], {
        type: "application/json",
      })
    );

    try {
      formData.append("document", files.inventoryReport);
      setUploadProgress(0);
      setUploadComplete((prev) => ({
        ...prev,
        inventoryReport: 1,
        epcReport: 0,
        inspectionReport: 0,
      }));

      // Axios request with token
      const tok = await fAuth.currentUser.getIdToken();
      const response = await axios.post(
        `${base_path}landlord/update-maintenance/${maintenanceId}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tok}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Check if the response is successful (status 200-299)
      if (response.status >= 200 && response.status < 300) {
        setMessage("Uploaded Successfully");
        navigate("/property/dashboard/regularmaintenance");
      } else {
        throw new Error("Upload Failed");
      }
    } catch (error) {
      setMessage("Upload Failed");
      navigate("/property/dashboard/updatemaintenance");
      setUploadProgress(0);
      setUploadComplete((prev) => ({
        ...prev,
        inventoryReport: 0,
        epcReport: 0,
        inspectionReport: 0,
      }));
      setFiles((prev) => ({
        ...prev,
        inventoryReport: null,
        epcReport: null,
        inspectionReport: null,
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="ProfileUploadContainer d-flex flex-column justify-content-between align-items-center p-5">
        <h1 className="HeadingProfile m-0 text-center">Maintenance Document</h1>
        <form
          onSubmit={handleSubmit}
          className="w-auto h-auto d-flex flex-column justify-content-center align-items-center gap-3"
        >
          <div className="InputRows d-flex flex-row justify-content-center align-items-center gap-3"></div>

          {/* Document Upload */}
          <div className="DocumentsSection w-auto h-auto d-flex flex-column justify-content-start align-items-center gap-3">
            <div className="UploadItmContainer d-flex flex-row justify-content-center align-items-center">
              <div
                className="TickContaoner d-flex flex-column justify-content-center align-items-center"
                style={
                  uploadComplete.inventoryReport === 1
                    ? { backgroundColor: "#22C55E", borderColor: "#22C55E" }
                    : {}
                }
              >
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.24121 3.20821L3.21954 5.18571L7.17454 1.23071"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="DocumentIconContainer d-flex flex-column justify-content-center align-items-center">
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2572 1.2915C11.2572 1.2915 4.85965 1.29484 4.84965 1.29484C2.54965 1.309 1.12549 2.82234 1.12549 5.13067V12.794C1.12549 15.114 2.56049 16.6332 4.88049 16.6332C4.88049 16.6332 11.2772 16.6307 11.288 16.6307C13.588 16.6165 15.013 15.1023 15.013 12.794V5.13067C15.013 2.81067 13.5772 1.2915 11.2572 1.2915Z"
                    fill="#21296D"
                    stroke="#21296D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.0967 12.5194H5.08008"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.0967 9.03068H5.08008"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.37616 5.54997H5.08032"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="DocumentNameContainer d-flex flex-column justify-content-center align-items-start">
                <p className="m-0 NameText">Inventory Report</p>
                {inventoryRef.current?.files[0] && (
                  <p className="m-0 Mb-text">{`${(
                    inventoryRef.current.files[0]?.size /
                    (1024 * 1024)
                  ).toFixed(3)} MB`}</p>
                )}
              </div>
              <button
                className="EachBrowsBtn"
                type="button"
                onClick={() => handleBrowseClick(inventoryRef)}
              >
                Browse
              </button>
              <input
                type="file"
                name="inventoryReport"
                onChange={handleFileChange}
                ref={inventoryRef}
                style={{ display: "none" }}
              />
              {files.inventoryReport && (
                <div
                  onClick={() => handleUnselect("inventoryReport")}
                  className="WrongIConContainer d-flex flex-column justify-content-center align-items-center"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9963 6.99561L7.00293 10.9889"
                      stroke="#21296D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.9974 10.9914L7.00073 6.9939"
                      stroke="#21296D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.612 1.29175H5.38783C2.87033 1.29175 1.29199 3.07425 1.29199 5.59675V12.4034C1.29199 14.9259 2.86283 16.7084 5.38783 16.7084H12.6112C15.137 16.7084 16.7087 14.9259 16.7087 12.4034V5.59675C16.7087 3.07425 15.137 1.29175 12.612 1.29175Z"
                      stroke="#21296D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>

            {/* Progress Bar */}
            {uploadProgress > 0 && (
              <div style={{ marginTop: "20px" }}>
                <progress value={uploadProgress} max="100" />
                <span> {uploadProgress}%</span>
              </div>
            )}

            {/* Submit Button */}
            <button className="EachBrowsBtn" type="submit">
              Submit
            </button>
          </div>
        </form>

        <div className="text-center">
          {message && (
            <p
              className="MessageText"
              style={
                message === "Uploaded Successfully"
                  ? { color: "#22C55E" }
                  : { color: "red" }
              }
            >
              {message}
            </p>
          )}
        </div>
      </div>
    </>
  );
}
