import React, { useState, useEffect } from "react";
import "./dashboard.css"; // Ensure you have relevant CSS styles defined
import api from "../../api"; // Your API setup
import StartTaskModal from "./StartTaskModal";
import StartTaskModalProperty from "../../../components/Property/global/StartTaskModalProperty";
import UploadMaintainancePdf from "../../../components/Property/global/UploadMaintainancePdf";
import { useSearchParams } from "react-router-dom";

// Function to format the date
function formatDate(dateInput) {
  const date = new Date(dateInput);
  return `${String(date.getUTCMonth() + 1).padStart(2, "0")}/${String(
    date.getUTCDate()
  ).padStart(2, "0")}/${date.getUTCFullYear()}`;
}

// Table Header Component
const TableHeader = ({ onSort, sortConfig }) => {
  const headers = [
    { key: "task_title", label: "Task" },
    { key: "due_date", label: "Date" },
    { key: "property_address", label: "Property" },
    { key: "task_description", label: "Task Details" },
    { key: "action", label: "Action", isAction: true },
    { key: "type", label: "Type" },
  ];

  return (
    <thead>
      <tr>
        {headers.map(({ key, label, isAction }) => (
          <th
            key={key}
            className={`col-${key.replace("_", "-")} ${
              !isAction ? "sortable" : ""
            }`}
            onClick={!isAction ? () => onSort(key) : undefined}
            style={{ cursor: !isAction ? "pointer" : "default" }} // Add pointer cursor for sorting
          >
            {label}{" "}
            {!isAction && sortConfig.key === key
              ? sortConfig.direction === "ascending"
                ? "▲"
                : "▼"
              : ""}
          </th>
        ))}
      </tr>
    </thead>
  );
};

// Main TaskHistory Component
const TaskHistory = () => {
  const [repairs, setRepairs] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "task_title",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const url = "/agent/dashboard/todo/";

  // Fetching task data from the API
  const fetchData = async (url = "/agent/dashboard/todo/", pageNo = 1) => {
    try {
      const { data } = await api.get(url, {
        params: {
          page: pageNo,
          limit: 10,
        },
      });
      setRepairs(data?.data?.results || []);
      setNextUrl(data.data.next);
      setPrevUrl(data.data.previous);
      setTotalCount(data.data?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setCurrentPage(pageNo);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle page changes for pagination
  const handlePageChange = (url) => {
    if (url) fetchData(url);
  };
  const handleReFetch = () => {
    fetchData("/agent/dashboard/todo/", currentPage);
  };

  // Sorting handler for table headers
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key, direction });
  };

  // Sorting the repairs array based on the current sort configuration
  const sortedRepairs = [...repairs].sort((a, b) => {
    const aValue = a[sortConfig.key] || "";
    const bValue = b[sortConfig.key] || "";
    return sortConfig.direction === "ascending"
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  return (
    <div>
      <h1 className="Heading" style={{
        textAlign: "left",
        fontSize: "1rem !important",
        marginBottom:"0 !important"
      }}>To Do Tasks:</h1>
      <table className="task-history-table">
        <TableHeader onSort={handleSort} sortConfig={sortConfig} />
        <tbody>
          {sortedRepairs.map((task, index) => (
            <TaskRow key={index} task={task} handleReFetch={handleReFetch} />
          ))}
        </tbody>
      </table>

      {/* Pagination Buttons */}
      <div
        className="ButtonGroupContainer BottomSection d-flex flex-row justify-content-end align-items-center gap-4"
        style={{ marginTop: "10px" }}
      >
        <button
          onClick={() => fetchData(url, currentPage - 1)}
          disabled={currentPage === 1}
          className="BtnPrevious d-flex flex-row justify-content-center align-items-center"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="btnText m-0">Previous</p>
        </button>

        <button
          onClick={() => fetchData(url, currentPage + 1)}
          disabled={currentPage >= Math.ceil(totalCount / 10)}
          className="BtnNext d-flex flex-row justify-content-center align-items-center"
        >
          <p className="btnText m-0">Next</p>
          <svg
            className="nxtIconSvg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

// TaskRow Component defined inline within TaskHistory
const TaskRow = ({ task, handleReFetch }) => {
  const defaultMessage = "Not available"; // Default error message
  const [startTaskModalOpen, setStartTaskModalOpen] = useState(false);
  const handleOpenStartTask = () => setStartTaskModalOpen(true);
  const handleCloseStartTask = () => setStartTaskModalOpen(false);
  return (
    <tr>
      <td>{task?.task_title || defaultMessage}</td>
      <td>{task?.due_date ? formatDate(task.due_date) : defaultMessage}</td>
      <td>{task?.property_address || defaultMessage}</td>
      <td>{task?.task_description || defaultMessage}</td>
      <td>
        <button onClick={handleOpenStartTask} className="action-button">
          Start Task
        </button>
      </td>
      <td>
        <button
          style={{
            padding: "5px 10px",
            outline: "none",
            border: "0px solid #18181B",
            textTransform:"capitalize",
            borderRadius:"12px",
            backgroundColor: task?.todo_type === "repair" ? "#007bff" : "#5cb85c",
            color:"white"

          }}
          className={`type-btn ${
            task?.todo_type === "repair" ? "warning-btn" : "success-btn"
          } `}
          type="button"
        >
          {task?.todo_type || defaultMessage}
        </button>
      </td>
      <StartTaskModalProperty
        isOpen={startTaskModalOpen && task?.todo_type === "repair"}
        currentStatus={task?.status}
        handleReFetch={() => handleReFetch()}
        selectedTaskId={task?.task_id}
        handleCloseStartTaskModal={handleCloseStartTask}
      />

      <UploadMaintainancePdf
        isOpen={startTaskModalOpen && task?.todo_type === "maintenance"}
        currentStatus={task?.status}
        handleReFetch={() => handleReFetch()}
        selectedTaskId={task?.task_id}
        handleCloseStartTaskModal={handleCloseStartTask}
      />
    </tr>
  );
};

export default TaskHistory;
