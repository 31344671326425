import React, { useEffect, useState } from "react";
import "./MDBContentCompStyle.css";
import HouseInfoCard from "./HouseInfoCard";
import api from "../../api";
import { formatNumber } from "../../lib/helper/global/number";

export default function CardsComp({ prop, age, tenancy }) {
  return (
    <>
      <div className="CardsContainer">
        <HouseInfoCard
          data={{ heading: prop?.property_type , subheading: "Property Type" }}
        />
        <HouseInfoCard
          data={{
            heading:   `${formatNumber(tenancy)} Years`,
            subheading: "Current Tenancy",
          }}
        />
        <HouseInfoCard
          data={{ heading:  `${formatNumber(age)} Years`, subheading: "House Age" }}
        />
      </div>
    </>
  );
}
