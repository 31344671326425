import { React, useContext } from "react";
import "../Common/InspectionInventory.css";
import img from "./SVG/Avatar.png";
import { MainContext } from "../../Context/MainContext";
import styles from "../../../modulecss/agents/reports/TanentSelfInspection.module.css";
export default function TentSelfInspectionTableRowsAgent({ data }) {
  const { dispatch } = useContext(MainContext);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const color = (col) => {
    switch (col.toLowerCase()) {
      case "excellent":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "completed":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "pass":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "a":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "fair":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "pending":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "c":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "good":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "d":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "repair":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "open":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "failed":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "b":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      default:
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
    }
  };
  return (
    <>
      <tr className="w-100 d-flex flex-row">
        <td className={`RowItm ${styles?.property_address_col}`}>
          {
            <span
              onClick={() => {
                dispatch({
                  type: "popup",
                  payload: {
                    popupData: data,
                    popupStae: true,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >{` ${data.PropertiesAdderess}`}</span>
          }
        </td>

        <td className={`RowItm ${styles?.result_col}`}>
          <div className="DocumentChip" style={color(data.Result)}>
            <p
              className="DocumentTypeText m-0"
              style={{
                textTransform: "capitalize",
              }}
            >
              {data.Result}
            </p>
          </div>
        </td>
        <td
          className={`RowItm creator d-flex flex-row justify-content-center align-items-center gap-2 ${styles?.create_by_col}`}
        >
          <img alt="img" className="profileimg" src={data.img} />
          <p className="creatorText m-0">{data.CreateBy}</p>
        </td>
        <td className={`RowItm ${styles?.date_col}`}>
          <p className="DateText m-0">{data.Date}</p>
        </td>
      </tr>
    </>
  );
}
