import { React, useContext } from "react";
import "../Common/InspectionInventory.css";
import img from "./SVG/Avatar.png";
import { MainContext } from "../../Context/MainContext";
import ViewButton from "../../../StructuredComponents/global/table/ViewButton";
import DownloadButton from "../../../StructuredComponents/global/table/DownloadButton";
import { addressToPdfName } from "../../../lib/helper/global/reports";
export default function InventoryTableRowsAgent({ data }) {
  const { dispatch } = useContext(MainContext);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const color = (col) => {
    switch (col.toLowerCase()) {
      case "excellent":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "completed":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "pass":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "a":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "fair":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "pending":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "c":
        return {
          backgroundColor: "#FFFBEB",
          borderColor: "#F59E0B",
          color: "#F59E0B",
        };
      case "good":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "d":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      case "repair":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "open":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "failed":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "b":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      default:
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
    }
  };
  return (
    <>
      <tr className="w-100 d-flex flex-row">
        <td
          className="RowItm"
          style={{ width: "40%", textAlign: "left", alignItems: "self-start" }}
        >
          {
            <span
              onClick={() => {
                dispatch({
                  type: "popup",
                  payload: {
                    popupData: data,
                    popupStae: true,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >{` ${data.PropertiesAdderess}`}</span>
          }
        </td>

        <td className="RowItm" style={{ width: "20%" }}>
          <div className="DocumentChip" style={color(data.Result)}>
            <p
              className="DocumentTypeText m-0"
              style={{ textTransform: "capitalize" }}
            >
              {data.Result}
            </p>
          </div>
        </td>
        <td className="RowItm" style={{ width: "20%" }}>
          <p className="DateText m-0">{data.Date}</p>
        </td>
        <td
          className="RowItm Action d-flex flex-row justify-content-center align-items-center gap-2"
          style={{ width: "20%" }}
        >
          <ViewButton
            Documenturl={data.Documenturl}
            openInNewTab={openInNewTab}
          />
          <DownloadButton
            Documenturl={data.Documenturl}
            filename={addressToPdfName(data.PropertiesAdderess)}
          />
        </td>
      </tr>
    </>
  );
}
