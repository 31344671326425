import React from 'react';
import styled from 'styled-components';

const ComingSoonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f8;
    color: #21296D;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #21296D;
`;

const Message = styled.p`
    font-size: 18px;
    color: #555;
    max-width: 500px;
    line-height: 1.6;
`;

const ComingSoon = () => {
    return (
        <ComingSoonContainer>
            <Title>Coming Soon</Title>
            <Message>
                We’re working hard to bring you something amazing! Please check back soon for updates.
            </Message>
        </ComingSoonContainer>
    );
};

export default ComingSoon;
