import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LRSImageComp from "./LRSImageComp";
import api from "../api";
import DataSpinner from "./Spinner/DataSpinner";
import LoadingPage from "./Spinner/Loader";
import { humanReadableDate } from "../lib/helper/global/date";
export default function LivingRoomSection() {
  const [loading, setLoading] = useState(false);
  const { itm, propertyId } = useParams();
  const [statusSeperatedImages, setStatusSeperatedImages] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [roomData, setRoomData] = useState({
    checkin: [],
    checkout: [],
    inspection: [],
    services: [],
  });
  const [getActive, setActive] = useState("checkin");
  const handleFetchImages = async (item_type = "Check In") => {
    try {
      setLoading(true);
      const response = await api.get(
        `/property/house-photos-by-type/${propertyId}`,
        {
          params: {
            item_type: itm,
          },
        }
      );
      const data = response.data;
      const tempStatusSeperatedImages = Object.keys(data).map((key) => ({
        stutus: key,
        images: data[key],
      }));
      setCurrentStatus(tempStatusSeperatedImages?.[0]?.stutus);
      setCurrentIndex(0);
      setStatusSeperatedImages(tempStatusSeperatedImages);
    } catch (error) {
      console.log("Error while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("landlord/dashboard/");
        const property_id = response.data.properties[0].property.id;
        const houseResponse = await api.get(
          `landlord/house-photo/${property_id}/`
        );

        const houseItems = houseResponse.data.find(
          (item) =>
            item.item_type.toLowerCase().replace(" ", "") === itm.toLowerCase()
        );

        if (houseItems) {
          const formattedData = {
            checkin: [],
            checkout: [],
            inspection: [],
            services: [],
          };
          houseItems.house_item.forEach((photo) => {
            const status = photo.status.toLowerCase();
            if (formattedData[status]) {
              formattedData[status].push({
                id: photo.id,
                image: photo.image,
                upload_date: photo.upload_date,
              });
            }
          });

          setRoomData(formattedData);
        }
      } catch (err) {
        console.log("Error while fetching data", err);
      }
    };
    fetchData();
    handleFetchImages();
  }, [itm]);

  const OnClickEvent = (event, index) => {
    setActive(event.target.dataset.status);
    handleFetchImages(event.target.dataset.status);
    setCurrentIndex(index);
  };

  const OptionalRender = () => {
    // const images = roomData[getActive];
    // if (!images || images.length === 0) {
    //   return <h1>No images available</h1>;
    // }
    return (
      <div className="d-flex flex-row flex-wrap gap-4">
        {statusSeperatedImages?.[currentIndex]?.images.map((photo) => (
          <LRSImageComp
            key={photo}
            image={photo?.image_url}
            upload_date={humanReadableDate(photo?.upload_date)}
            loading="lazy"
          />
        ))}
      </div>
    );
  };

  return (
    <>
      {/* {loading && <DataSpinner/>} */}
      <div className="LivingRoomSection p-3 d-flex flex-column justify-content-center align-items-center">
        <LoadingPage loading={loading} />
        <div className="TabSection d-flex flex-row">
          {statusSeperatedImages.map((status, index) => (
            <button
              data-status={status.stutus}
              className={`TabBtn ${
                getActive === status.stutus ? "TabBtnActive" : ""
              }`}
              style={{
                textTransform: "uppercase",
              }}
              onClick={(e) => OnClickEvent(e, index)}
            >
              {status.stutus}
            </button>
          ))}
        </div>
        <div className="ContentSection py-3">{OptionalRender()}</div>
      </div>
    </>
  );
}
