import { React, useState, useEffect, useMemo } from "react";
import "./OpenRepairsSection.css";
import OpenRepairSectionSideBar from "./OpenRepairSectionSideBar";
import ORSMainContent from "./ORSMainContent";
import ORSMCOpenRepair from "./ORSMCOpenRepairs";
import ORTableContainer from "./ORTableContainer";
import ORPTableContainer from "./ORPTableContainer";
// import {data,sizeOfData} from "./MOCK_DATA";
export default function OpenRepairsSection() {
  const [repairScore, setRepairScore] = useState(0);

  const [getOption, setOption] = useState("Open Repairs");
  const columns = useMemo(
    () => [
      {
        Header: "Repair Details",
        className: "RepairDetailsColumn",
        accessor: "document.id",
      },
      {
        Header:
          getOption === "Completed Repairs" ? "Completion Date" : "Created At",
        className: "CompletionDateColumn",
        accessor: "date",
      },
      {
        Header: "Status",
        className: "SatusColumn",
        accessor: "document.type",
      },
      {
        Header: "Completion Report",
        className: "CompletionReportColumn",
        accessor: "document.title",
      },
      {
        Header: "Repair Cost",
        className: "RepairCostColumn",
        accessor: "cretor.name",
      },
    ],
    [getOption]
  );
  const clickHandler = (name) => {
    // console.log(name);
    setOption(name);
  };
  const optionRender = () => {
    switch (getOption) {
      case "Open Repairs":
        return (
          <ORTableContainer
            columns={columns}
            name={"table1"}
            status={"open"}
            setRepairScore={setRepairScore}
          />
        );

      case "Completed Repairs":
        return (
          <ORTableContainer
            columns={columns}
            name={"table1"}
            status={"completed"}
            setRepairScore={setRepairScore}
          />
        );
      case "Pending Repairs":
        return (
          <ORTableContainer
            columns={columns}
            name={"table1"}
            status={"pending"}
            setRepairScore={setRepairScore}
          />
        );
      // return <ORTableContainer columns={columns} data={data} sizeOfData={sizeOfData} name={"table1"}/>;
      default:
        return <h1></h1>;
    }
  };
  return (
    <>
      <div
        className="OpenReapairSection"
        style={{ padding: "0px 0px 0px 0px" }}
      >
        <div className="container-fluid p-0 w-100 h-100">
          <div className="row m-0 w-100 h-100">
            <div className="col-4 p-0 pe-2 h-100">
              <OpenRepairSectionSideBar
                repairScore={repairScore}
                clickEvent={clickHandler}
              />
            </div>
            <div className="col-8 p-0 ps-1 h-100">
              <ORSMainContent>{optionRender()}</ORSMainContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
