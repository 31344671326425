import {React,useMemo} from "react";
import './Report.css';
import PropertyTableContainer from "../Properties/PropertyTableContainer";
import REPCRows from "./REPCRows";
import styles from '../../../modulecss/agents/reports/EpcReport.module.css'
export default function ReportEPCTable({data,children}){
    const columns = useMemo(
        ()=>[
            {
                Header:"Properties Address",
                className:`RPropertiesAddressCol ${styles?.property_address_col}`,
                accessor: "document.id"
            },
            {
                Header:"Document",
                className:`RDocumentCol ${styles?.document_col}`,
                accessor: "date"
            },
            {
                Header:"Current Rating",
                className:`RCurrentRatingCol ${styles?.results_col}`,
                accessor: "document.type"
            },
            {
                Header:"Date",
                className:`RDateCol ${styles?.date_col}`,
                accessor: "document.title"
            },
            // {
            //     Header:"Action",
            //     className:"RActionCol",
            //     accessor: "document.title"
            // },
        ],[]);
    return(
        <>
            <div className="ReportEPCTableContainerAgent">
                <PropertyTableContainer columns={columns} data={data} tablename={'EPCtable'} >
                    <REPCRows/>
                </PropertyTableContainer>
            </div>
        </>
    );
}
