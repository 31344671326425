import { React, useState, useContext, useEffect } from "react";
import Communication from "./Communication";
import PropertyTimeline from "./PropertyTimeline";
import PropertyDetails from "./PropertyDetails";
import { MainContext } from "../../Context/MainContext";
import Modal from "./Model";
import api from "../../api";
import { RepairStatus } from "./RepairStatusNew";
import { BottomBar } from "./BottomBar";
const MaintainanceTimeline = () => {
  const { popupStae, popupId, dispatch } = useContext(MainContext);
  const [timelinedata, Settimelinedata] = useState([]);
  const [person, setPerson] = useState([]);
  const [pd, setpd] = useState("");
  const [repairData, setRepairData] = useState({});
  // console.log(popupId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`agent/repair/timeline/${popupId}`);
        Settimelinedata(response.data.timeline_data || []);
        setpd(response.data.property_data[0]);
        setPerson(response.data.communication_data || []);
        setRepairData(response?.data?.repair_data || []);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          console.log("Data not found");
        } else {
          console.log("An error occurred while fetching data");
        }
      }
    };
    if (popupId) {
      fetchData();
    }
  }, [popupId]);

  const people = [
    {
      name: "Khadijah Ali",
      role: "Landlord",
      image: "path-to-khadijah-image",
      icons: {
        person: "path-to-person-icon",
        call: "path-to-call-icon",
        message: "path-to-message-icon",
      },
    },
    {
      name: "Sara Jane",
      role: "Tenant",
      image: "path-to-sara-image",
      icons: {
        person: "path-to-person-icon",
        call: "path-to-call-icon",
        message: "path-to-message-icon",
      },
    },
  ];

  const timelineEvents = [
    {
      date: "JUL 24, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "Khadijah Ali",
        role: "Landlord",
        roleClass: "landlord",
        image: "path-to-person-image",
      },
    },
    {
      date: "JUL 25, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "Sara Jane",
        role: "Tenant",
        roleClass: "tenant",
        image: "path-to-person-image",
      },
    },
    {
      date: "JUL 26, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "Sara Jane",
        role: "Tenant",
        roleClass: "tenant",
        image: "path-to-person-image",
      },
    },
    {
      date: "JUL 27, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "John Doe",
        role: "Agent",
        roleClass: "agent",
        image: "path-to-person-image",
      },
    },
    ,
    {
      date: "JUL 27, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "John Doe",
        role: "Agent",
        roleClass: "agent",
        image: "path-to-person-image",
      },
    },
    ,
    {
      date: "JUL 27, 2024",
      text: "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "John Doe",
        role: "Agent",
        roleClass: "agent",
        image: "path-to-person-image",
      },
    },
  ];
  const [open, setOpen] = useState(popupStae);
  useEffect(() => {
    setOpen(popupStae);
  }, [popupStae]);

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "popup",
      payload: {
        popupData: {},
        popupStae: false,
        popupId: "",
      },
    });
  };
  return (
    <div className={`${open ? "modal-shadow" : ""}`}>
      <Modal isOpen={open} onClose={handleClose}>
        <div>
          <h5 className="heading-text">Property Details</h5>
          <PropertyDetails data={pd} />
          <h5 className="heading-text">Repair Status</h5>
          <RepairStatus data={repairData} />
          <h5 className="heading-text">Communication</h5>
          <Communication people={person} />
          {/* <h5 className="heading-text">Photos</h5>
          <PropertyTimeline timelineEvents={timelinedata} /> */}
          {/* <div className="prop-btn">
            <button className="property-button">Go to Property</button>
          </div> */}
          <BottomBar data={repairData} />
        </div>
      </Modal>
    </div>
  );
};

export default MaintainanceTimeline;
