export const transformMainInspectionData = (data = []) => {
  return data.map((item) => ({
    id: item.id,
    title: item?.title,
    date: item?.date,
    reportType: item?.document_type,
    description: item?.description,
    author: item?.tenant_name,
    author_profile_photo: item?.tenant_profile_photo,
    due_date: item?.due_date,
    result: item?.result,
    document_url: item?.document,

    actions: {
      view: true,
      download: true,
    },
  }));
};
