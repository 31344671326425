import { React, useState, useEffect } from "react";
import "./Report.css";
import AllTablesSection from "../Properties/AllTablesSections";
import MaintainanceTimeline from "../MaintainanceTimeline/MaintainanceTimeline";
import TentSelfInspectionTable from "./TentSelfInspectionTable";
import RCOTable from "./RCOTable";
import api from "../../api";
export default function RCheckOutTableContainer() {
  const [tableData, setTableData] = useState([
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Fair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Good",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Excellent",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Repair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Repair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Good",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Excellent",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Fair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Excellent",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Repair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Repair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Good",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Excellent",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
    {
      PropertiesAdderess: "01 Prince Consort Road, Kelton, DG1 9UH.",
      Document: "Self Inspection",
      Result: "Fair",
      CreateBy: "khadijah ali",
      Date: "12/05/2024",
    },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("agent/checkout/");
        const property = response.data;
        console.log(property.repair_data);
        const epcData =
          property.epc_data?.map((data) => ({
            id: data.id || "N/A",
            PropertiesAdderess: data.property_address || "No Address Provided",
            Document: "Self Inspection",
            img: data.tenant?.profile_photo || "No Image",
            Result: data.current_score || "No Result",
            CreateBy: data.tenant?.name || "Unknown",
            Date: data?.report_date ? ConvertDate(data.report_date) : "No Date",
            Documenturl: data.document || "No Document",
          })) || [];

        const gasData =
          property.epc_data?.map((data) => ({
            id: data.id || "N/A",
            PropertiesAdderess: data.property_address || "No Address Provided",
            Document: "Gas Inspection",
            img: data.tenant?.profile_photo || "No Image",
            Result: data.result || "No Result",
            CreateBy: data.tenant?.name || "Unknown",
            Date: data?.report_date ? ConvertDate(data.report_date) : "No Date",
            Documenturl: data.document || "No Document",
          })) || [];

        // Mapping Repair Data
        const repairData =
          property.repair_data?.map((data) => ({
            id: data.id || "N/A",
            PropertiesAdderess: data.property_address || "No Address Provided",
            Document: "Repair Report",
            img: data.tenant?.profile_photo || "No Image",
            Result: data.status || "No Result",
            CreateBy: data.tenant?.name || "Unknown",
            Date: data?.completion_date
              ? ConvertDate(data.completion_date)
              : "No Date",
            Documenturl: data.completion_report || "No Document",
          })) || [];
        console.log(repairData);

        // Combine all data (EPC, Gas, Repair) into one unified array
        const combinedData = [...epcData, ...repairData, ...gasData];
        //   console.log(combinedData)

        // Set the table data with combined data
        setTableData(combinedData);

        // Set the table size based on the number of entries
        SetTableSize(combinedData.length);
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    fetchData();
  }, []);
  // const [tableData,setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(0);

  function ConvertDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("agent/report-inventory/");
        const property = response.data;
        const repairdata = property.map((data) => ({
          id: data.property_id,
          PropertiesAdderess: data.property.address,
          Document: "Self Inspection",
          img: data.profile_photo,
          Result: data.result,
          CreateBy: data.tenant_name,
          Date: ConvertDate(data.date),
          Documenturl: data.document,
        }));
        setTableData(repairdata);
        SetTableSize(repairdata.length);
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    fetchData();
  }, []);

  const [filterData, setFilterData] = useState([]);

  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(0);
  const data = (start, range) => {
    const datapass = searchData == "" ? tableData : SearchTableData;
    const Size = searchData == "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 10);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };
  const PTsearchingData = (searchState) => {
    const filteredData = tableData.filter((row) => {
      return (
        row.PropertiesAdderess.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.Document.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.Result?.toLowerCase().includes(
          searchState.toString().toLowerCase()
        ) ||
        row.CreateBy.toString()
          .toLowerCase()
          .includes(searchState.toString().toLowerCase()) ||
        row.Date.toLowerCase().includes(searchState.toString().toLowerCase())
      );
    });
    return filteredData;
  };

  useEffect(() => {
    setSearchTableData(PTsearchingData(searchData));
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);
  return (
    <div className="ReportSection">
      <div className="Container-fluid p-0">
        <div class="row m-0">
          <div className="col-12 p-0">
            <MaintainanceTimeline />
            <AllTablesSection
              className={"ReportEPCTAbleContent"}
              data={data}
              tableSize={searchData == "" ? tableSize : SearchTableDataSize}
              setSearchData={setSearchData}
              pageSize={10}
            >
              <RCOTable />
            </AllTablesSection>
          </div>
        </div>
      </div>
    </div>
  );
}
