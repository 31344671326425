import { useState } from 'react';
import React from "react";
import './Signup.css';
import Signup from './Signup';
export default function SignupPage({updateSate}){
    const [pageRender,setpageRender] = useState('login');
    return(
        <div className="MaintaontainerLogin">
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-4 p-0">
                        <div className="signupimgcontainer">
                            <img className="signupimg w-100 h-100" src={'/login.jpg'} alt="signupimg"/>
                        </div>
                    </div>
                    <div className="col-8 p-0">
                        <div className="signupformcontainer d-flex flex-column justify-content-between">
                            {
                         <Signup setpageRender={setpageRender}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}