export function addressToPdfName(address="file") {
    
    // Remove any characters that are not letters, numbers, spaces, or commas
    let cleanAddress = address.replace(/[^\w\s,]/gi, '');
  
    // Replace spaces and commas with underscores to make it URL safe
    cleanAddress = cleanAddress.replace(/[\s,]+/g, '_');
  
    // Return the address as a file name with .pdf extension
    return `${cleanAddress}.pdf`;
  }