import React, { useState, useEffect } from "react";
import "./Invite.css";
import api from "../api";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Invite = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [loading, setLoading] = useState(false)
  const [typingTimeout, setTypingTimeout] = useState(0);
  const navigate = useNavigate();

  const fetchPropertySuggestions = async (value) => {
    try {
        setLoading(true)
      const searchParams = new URLSearchParams({
        limit: 100,
        page: 1,
      });
      let url = `properties/autocomplete/`;
      if (value) searchParams.append("search", value);
      else url = `agent/property-list/`;
      const response = await api.get(url, {
        params: searchParams,
      });
      if (response.status === 200) {
        const arrlist =
          url === "properties/autocomplete/"
            ? response.data
            : response.data?.data?.results;
        const formattedOptions = arrlist?.map(
          (property) => ({
            value: property.id,
            label: property.address, // Assuming 'address' is the property field you want to show
          })
        );
        setPropertyOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching properties", error);
    }
    finally{
        setLoading(false)
    }
  };
  useEffect(() => {
    fetchPropertySuggestions("");
  }, []);

  const handlePropertyChange = (selectedOption) => {
    setPropertyId(selectedOption?.value || ""); // Store the selected property ID
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("accounts/request", {
        name: name,
        email: email,
        property_id: propertyId, // Send selected property ID to backend
      });
      if (response.status === 200) {
        window.alert("Successfully sent invitation");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div className="InviteContainer w-100 d-flex flex-column justify-content-start align-items-center ">
      <h2 className="Heading m-0 mb-5">Invite Tenant</h2>
      <form
        className="d-flex flex-column w-50 gap-3 mt-5"
        onSubmit={handleSubmit}
      >
        <div className="NameContainer d-flex flex-column gap-1 align-items-center">
          <label htmlFor="name" className="labelText text-start w-75">
            Name:
          </label>
          <input
            className="w-75 inputField"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="NameContainer d-flex flex-column gap-1 align-items-center">
          <label htmlFor="email" className="text-start w-75">
            Email:
          </label>
          <input
            className="w-75 inputField"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="NameContainer d-flex flex-column gap-1 align-items-center">
          <label htmlFor="property" className="text-start w-75">
            Property:
          </label>
          <Select
            id="property"
            className="w-75 "
            options={propertyOptions} // Property options fetched from the API
            onInputChange={(inputValue) => {
              // Fetch suggestions based on user input
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                    fetchPropertySuggestions(inputValue);
                }, 300)
              );
            }}
            onChange={handlePropertyChange} // Handle selection
            placeholder="Type to search for a property"
            isClearable
            isLoading={loading}
          />
        </div>

        <button type="submit" className="submitBtn text-center">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Invite;
