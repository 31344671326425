export const optionToSort = [
  { value: "property_address", label: "Properties Address" },
  { value: "description", label: "Description" },
  { value: "title", label: "Title" },
  { value: "status", label: "Status" },
];

export let optionToFilter = [
  {
    name: "status",
    label: "status",
    placeholder: "Select status",
    options: [
      { value: "open", label: "Open" },
      { value: "pending", label: "Pending" },
    ],
  },
];

export const oRoptionToSort = [
  { value: "property_address", label: "Properties Address" },
  { value: "description", label: "Description" },
  { value: "status", label: "Status" },
];

export let oRoptionToFilter = [
  {
    name: "status",
    label: "status",
    placeholder: "Select status",
    options: [
      { value: "Building Report", label: "Building Report" },
      { value: "Arranging Engineer", label: "Arranging Engineer" },
      { value: "Arranging Access", label: "Arranging Access" },
      { value: "Completion Report", label: "Completion Report" },
    ],
  },
];
