import { React, useMemo } from "react";
import "./MaintenanceandRepair.css";
import PropertyTableContainer from "../Properties/PropertyTableContainer";
import MRORTableRows from "./MRORTableRows";
export default function MROpenRepairTable({ data }) {
  const columns = useMemo(
    () => [
      {
        Header: "Properties Address",
        className: "headerOR-1",
        accessor: "document.id",
      },
      {
        Header: "Description",
        className: "headerOR-2",
        accessor: "expiry_date",
      },
      {
        Header: "Repair Status",
        className: "headerOR-3",
        accessor: "document.type",
      },
    ],
    []
  );
  return (
    <>
      <div className="MROpenRepairTableContainer">
        <PropertyTableContainer
          columns={columns}
          data={data}
          tablename={"ORTable"}
        >
          <MRORTableRows data={data} />
        </PropertyTableContainer>
      </div>
    </>
  );
}
