import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingPage from "../../Spinner/Loader";

// Styled components
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 400px;
`;

const ModalHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h5`
  margin: 0;
`;

const ModalBody = styled.div`
  padding: 1rem;
`;

const ModalFooter = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &.btn-primary {
    background-color: #007bff;
    color: white;
    &:hover {
      background-color: #0056b3;
    }
  }
  &.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid #6c757d;
    color: #6c757d;
    &:hover {
      background-color: #6c757d;
      color: white;
    }
  }
  &.btn-outline-warning {
    background-color: transparent;
    border: 1px solid #ffc107;
    color: #ffc107;
    &:hover {
      background-color: #ffc107;
      color: white;
    }
  }
`;

const UploadInputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const UploadLabel = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const UploadInput = styled.input`
  padding: 0.5rem;
  border: 2px dashed #007bff;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
  &:hover {
    border-color: #0056b3;
  }
  &:focus {
    border-color: #0056b3;
  }
`;

const FileNameDisplay = styled.p`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #6c757d;
`;

const UploadMaintainancePdf = ({
  isOpen = false,
  handleCloseStartTaskModal = () => {},
  selectedTaskId,
  handleReFetch,
}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const modalRef=useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
    } else {
      toast.error("Please upload a valid PDF file.");
      setPdfFile(null);
    }
  };

  const handleStartTask = async () => {
    if (!pdfFile) {
      toast.error("Please select a PDF file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("document", pdfFile); // Append the PDF file

    try {
      setLoading(true);
      const response = await api.put(
        `agent/maintenance/update/${selectedTaskId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Task updated successfully");
    } catch (error) {
      toast.error("Failed to upload PDF and update the task");
    } finally {
      setLoading(false);
      handleCloseStartTaskModal();
      handleReFetch();
    }
  };
  const handleScroll = (e) => {
    if (modalRef.current) {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // Calculate the modal dimensions
      const modalHeight = modalRef.current.offsetHeight;
      const modalWidth = modalRef.current.offsetWidth;

      // Get the computed transform scale of .app-container
      const appContainer = document.querySelector(".app-container");
      const transformStyle = window.getComputedStyle(appContainer).transform;

      // Check if scale is applied (and less than 1)
      let scale = 1;
      if (transformStyle !== "none") {
        const matrix = transformStyle.match(/matrix\(([^)]+)\)/);
        if (matrix) {
          scale = parseFloat(matrix[1].split(", ")[0]); // Extract the scale value from the matrix
        }
      }

      // Conditionally calculate an extra offset only if scale is less than 1
      const extraOffset = scale < 1 ? (scrollY / 100) * 15 : 0; // Modify the multiplier as needed

      // Calculate the center position with conditional offset
      const topPosition =
        scrollY + (viewportHeight - modalHeight) / 2 + extraOffset;
      const leftPosition = (viewportWidth - modalWidth) / 2;

      // Set the modal position
      modalRef.current.style.top = `${topPosition}px`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.getElementById("root").style.backgroundColor =
        "rgba(0, 0, 0, 0.5)";
      handleScroll();
    } else {
      document.getElementById("root").style.backgroundColor = "#f4f4f5";
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      document.getElementById("root").style.backgroundColor = "#f4f4f5";
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalBackdrop onClick={handleCloseStartTaskModal} />
      <ModalContainer ref={modalRef}>
        <ModalHeader>
          <ModalTitle>Upload Maintenance PDF</ModalTitle>
          <button
            type="button"
            className="btn-close"
            onClick={handleCloseStartTaskModal}
          ></button>
        </ModalHeader>
        <ModalBody>
          <UploadInputContainer>
            <UploadLabel>Upload PDF</UploadLabel>
            <UploadInput
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
            />
            {pdfFile && (
              <FileNameDisplay>Selected file: {pdfFile.name}</FileNameDisplay>
            )}
          </UploadInputContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-outline-secondary"
            onClick={handleCloseStartTaskModal}
          >
            Cancel
          </Button>
          <Button
            className="btn-outline-warning"
            onClick={() => setPdfFile(null)} // Reset the file selection
          >
            Clear
          </Button>
          <Button className="btn-primary" onClick={handleStartTask}>
            Upload
          </Button>
        </ModalFooter>
      </ModalContainer>
      <LoadingPage loading={loading} />
    </>
  ) : null;
};

export default UploadMaintainancePdf;
