export const tansformLocationFromProperty = (property) => {
  return {
    LocationSteet: property.address,
    status: property.status,
    ZipCode: property.zip_code,
    bedroomno: property.bedroom_count,
    bathroomno: property.bathroom_count,
    halls: property.living_room_count,
    latitude: property.latitude,
    longitude: property.longitude,
    house_name:property.house_name
  };
};
