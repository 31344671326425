import React from "react";
import { Navigate } from "react-router-dom";
import { fAuth } from "../lib/services/firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from "./AgentComponents/Spinner/Spinner";

const isAuthenticated = (user, loading, error) => {
  return user && !loading && !error;
};

const PrivateRoute = ({ element, ...rest }) => {
  const [user, loading, error] = useAuthState(fAuth);
  return isAuthenticated(user, loading, error) ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};

export const AgentprivateRoute = ({ element }) => {
  const [user, loading, error] = useAuthState(fAuth);
  if (loading) return <Spinner />;
  return isAuthenticated(user, loading, error) ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
