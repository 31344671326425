import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "./dashboard.css";
import { FaLock } from "react-icons/fa";
import api from "../../api";
import MapAutocomplete from "../../../StructuredComponents/global/map/MapAutocomplete";

const libraries = ["places", "geometry", "drawing"];
const MapSection = () => {
  const [map, setMap] = useState(null);
  const [search, setSearch] = useState("");
  const [center, setCenter] = useState({ lat: 51.5248716, lng: 0.002207 });
  const [apiAddresses, setApiAddresses] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBiSpA-A3DHxVgxiGt-IWtBaOSQ01BBKqY",
    libraries,
  });

  const fetchData = async (url = "/agent/property-maps/") => {
    try {
      const response = await api.get(url);
      const data = response?.data || [];
      setApiAddresses(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loadError) return <div>Error loading the map</div>;
  if (!isLoaded) return <div>Loading..</div>;

  const handleSearch = (event) => {
    event.preventDefault();
    setCenter({ lat: 37.774929, lng: -122.419418 });
  };

  return (
    <>
      <div className="map-section">
        <div className="map-header">
          <div>
            <h2 className="map-title">Map</h2>
            <h6
              style={{
                color: "",
                fontSize: "14px",
                fontWeight: "300",
                margin: "0",
                padding: "5px",
                borderRadius: "4px",
              }}
            >
              Recent property
            </h6>
          </div>
          <MapAutocomplete
            data={apiAddresses?.filter(
              (item) =>
                (item.position && item?.position?.lat && item?.position?.lng)
            )}
            onLocationSelect={setSelectedLocation}
          />
        </div>

        <div className="map-container">
          <GoogleMap
            mapContainerClassName="map"
            center={center}
            zoom={7}
            onLoad={(mapInstance) => setMap(mapInstance)}
          >
            {/* Render markers from dummyArray */}
            {apiAddresses.map((location, index) => (
              <Marker
                key={index}
                position={location.position}
                onClick={() => setSelectedLocation(location)} // Show InfoWindow on click
              />
            ))}

            {/* InfoWindow to show details of the selected marker */}
            {selectedLocation && (
              <InfoWindow
                position={selectedLocation.position}
                onCloseClick={() => setSelectedLocation(null)}
              >
                <div>
                  <h4>{selectedLocation.address}</h4>
                  <p>House Name: {selectedLocation.house_name}</p>
                  <p>Post Code: {selectedLocation.zip_code}</p>
                  <p>Property Type: {selectedLocation.property_type}</p>
                  <p>Score: {selectedLocation.score.toFixed(2)}</p>
                  <p>Tenant Name: {selectedLocation.tenant_name || "N/A"}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      </div>
      <div className="">
        <FaLock className="lock-icon" />
        <div className="updated-message">Updated soon</div>
      </div>
    </>
  );
};

export default MapSection;
