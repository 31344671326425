import {React,useMemo} from "react";
import '../Common/InspectionInventory.css';
import './Report.css';
import PropertyTableContainer from "../Properties/PropertyTableContainer";
import TentSelfInspectionTableRowsAgent from "./TentSelfInspectionTableRowsAgent";
import styles from '../../../modulecss/agents/reports/InventoryReport.module.css'
import InventoryTableRowsAgent from "./InventoryTableRowsAgent";
export default function InventoryTable({data,children}){
    const columns = useMemo(
        ()=>[
            {
                Header:"Properties Address",
                className:`ASIPropertiesAddressCol ${styles?.property_address_col} `,
                accessor: "document.id"
            },
          
            {
                Header:"Result",
                className:`ASIResultCol ${styles?.results_col}`,
                accessor: "document.type"
            },
          
            {
                Header:"Date",
                className:`ASIDateCol ${styles?.date_col}`,
                accessor: "cretor.name"
            },
            {
                Header:"Action",
                className:`ASIActionCol ${styles?.action_col}`,
                accessor: "expiry_date"
            }
        ],[]);
    return(
        <>
            <div className="AgentSelfInspectionTableContainer">
                <PropertyTableContainer columns={columns} data={data} tablename={'ATSItable'} >
                    <InventoryTableRowsAgent/>
                </PropertyTableContainer>
            </div>
        </>
    );
}
