import { React, useContext, useEffect, useState } from "react";
import "./Properties.css";
import { CardContainer } from "./PropertiesCount";
import AllTablesSection from "./AllTablesSections";
import PropertyTable from "./PropertyTable";
import api from "../../api";
import SortModal from "./SortModal";
import FilterModal from "./FilterModal";
import MainTenanceSchedule from "./MainTenanceSchedule";
import { MainContext } from "../../Context/MainContext";
import { optionToFilter, optionToSort } from "./constant";
// import { data,sizeOfData, dataobjArray } from "./PropertiesData";
export default function Properties() {
  const [tableData, setTableData] = useState([]);
  const [tableSize, SetTableSize] = useState(10);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [nop, SetNop] = useState("");
  const [orc, Setorc] = useState("");
  const [omc, Setomc] = useState("");
  const [nvacant, Setnvacant] = useState("");
  const [noccupy, Setoccupy] = useState("");
  const [ngas, Setngas] = useState("");
  const [nepc, Setnepc] = useState("");
  const [necir, Setnecir] = useState("");
  const { dispatch } = useContext(MainContext);
  const [url, setUrl] = useState("agent/property-list/");
  const [totalDataCount, setTotalDataCount] = useState();

  // Sorting & filter states
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    property_score: "",
    compliance_level: "",
    open_repairs: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("agent/property/");
        console.log(response.data);
        SetNop(response.data.number_of_properties);
        Setorc(response.data.open_repairs_count);
        Setomc(response.data.open_maintenance_count);
        Setnvacant(response.data.vacant_properties_count);
        Setoccupy(response.data.occupied_properties_count);
        Setngas(response.data.gas_safety);
        Setnepc(response.data.epc);
        Setnecir(response.data.eicr);
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    fetchData();
  }, []);

  const fetchData = async (url = "agent/property-list/") => {
    try {
      const response = await api.get(url);
      const properties = response?.data?.data?.results;
      const updatedTableData = properties.map((property) => ({
        id: property?.id,
        PropertiesAdderess: property.address,
        TimeinProperty: property.house_age
          ? `${property.house_age} Years`
          : "N/A",
        PropScore: property.score,
        Tenancy: property.tenancy,
        complianceLevel:
          property.status.charAt(0).toUpperCase() + property.status.slice(1),
        OpenRepairs: property.open_repair_count,
        InspectionsDue: property.inspection_repair_count,
      }));
      console.log("responseresponse", response);
      setTableData(updatedTableData);
      SetTableSize(response?.data?.data?.count); // Total number of items
      setTotalDataCount(response.data.data.count);
      setNextUrl(response.data.data.next);
      setPrevUrl(response.data.data.previous);
    } catch (err) {
      console.log("Error while fetching data", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [searchData, setSearchData] = useState("");
  const [SearchTableData, setSearchTableData] = useState([]);
  const [SearchTableDataSize, setSearchTableDataSize] = useState(10);
  const data = (start, range) => {
    console.log(tableData);
    console.log(tableSize);
    const datapass = searchData === "" ? tableData : SearchTableData;
    const Size = searchData === "" ? tableSize : SearchTableDataSize;
    if (range <= 0) {
      return datapass.slice(0, 2);
    } else if (range > Size) {
      return datapass.slice(start, Size);
    } else {
      return datapass.slice(start, range);
    }
  };

  const PTsearchingData = (searchState) => {
    const filteredData = tableData.filter((row) => {
      const searchStr = searchState.toString().toLowerCase();

      return (
        row.PropertiesAdderess.toLowerCase().includes(searchStr) ||
        row.Tenancy.toLowerCase().includes(searchStr) ||
        row.TimeinProperty?.toLowerCase().includes(searchStr) ||
        row.PropScore.toString().toLowerCase().includes(searchStr) ||
        row.complianceLevel.toLowerCase().includes(searchStr) ||
        row.OpenRepairs.toString().toLowerCase().includes(searchStr) || // Convert to string
        row.InspectionsDue.toString().toLowerCase().includes(searchStr) // Convert to string
      );
    });

    return filteredData;
  };

  useEffect(() => {
    setSearchTableData(PTsearchingData(searchData));
    setSearchTableDataSize(SearchTableData.length);
  }, [searchData]);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleApplySort = () => {
    let newUrl;
    if (sortOption === "") {
      newUrl = url
        .replace(/(&?ordering=[^&]*)/, "")
        .replace(/(\?&)|(&\?)/, "?");
      newUrl = newUrl.endsWith("?") ? newUrl.slice(0, -1) : newUrl;
    } else if (url.includes("ordering=")) {
      newUrl = url.replace(/(ordering=)[^&]*/, `$1${sortOption}`);
    } else {
      const separator = url.includes("?") ? "&" : "?";
      newUrl = `${url}${separator}ordering=${sortOption}`;
    }
    setUrl(newUrl);
    fetchData(newUrl);
    setSortModalOpen(false);
  };

  const handleApplyFilter = (options) => {
    setFilterOptions(options);
    const { property_score, compliance_level, open_repairs } = options;
    let currentUrl = new URL(url, window.location.origin); // Use URL object for easier manipulation

    // Update or set the query parameters
    if (property_score) {
      currentUrl.searchParams.set("property_score", property_score);
    } else {
      currentUrl.searchParams.delete("property_score");
    }

    if (compliance_level) {
      currentUrl.searchParams.set("compliance_level", compliance_level);
    } else {
      currentUrl.searchParams.delete("compliance_level");
    }

    if (open_repairs) {
      currentUrl.searchParams.set("open_repairs", open_repairs);
    } else {
      currentUrl.searchParams.delete("open_repairs");
    }

    // Fetch data with the updated URL
    const newUrl = currentUrl.toString().replace(window.location.origin, "");
    setUrl(newUrl);
    fetchData(newUrl);
    setFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilterOptions({
      property_score: "",
      compliance_level: "",
      open_repairs: "",
    });
  };

  return (
    <>
      <div className="PropertiesSection">
        <div className="container-fluid p-0">
          <div className="row m-0 g-3" id="TopSection">
            <div
              className="col-12 col-md-6 col-lg-4 ps-0 pe-2 "
              id="rowcolumns"
            >
              <CardContainer className={"justify-content-around"}>
                <h1 className="Heading m-0">Number of properties</h1>
                <p className="Value m-0">{nop}</p>
                <div className="ProgressContainer d-flex flex-column gap-2">
                  <div className="LinerarPgrogress">
                    <div
                      className="Loader"
                      style={{ width: `${(noccupy / nop) * 100}%` }}
                    ></div>
                  </div>
                  <div className="Labels d-flex flex-row align-items-center gap-2">
                    <div className="Dot Active"></div>
                    <p className="Text m-0">Occupied</p>
                    <p className="Value m-0">{noccupy}</p>
                  </div>
                  <div className="Labels d-flex flex-row align-items-center gap-2">
                    <div className="Dot"></div>
                    <p className="Text m-0">Vacant</p>
                    <p className="Value m-0">{nvacant}</p>
                  </div>
                </div>
              </CardContainer>
            </div>
            <div className="col-12 col-md-6 col-lg-4 ps-2 pe-2" id="rowcolumns">
              <CardContainer className={"OpenRepairRegularMaintenance"}>
                <h1 className="Heading m-0">
                  Open Repairs/Regular Maintenance
                </h1>
                <div className="d-flex flex-row OpenRepairRegularMaintenanceContent">
                  <div className="Cards d-flex flex-column justify-content-center align-items-center px-3 py-3">
                    <h1 className="Value m-0">{orc}</h1>
                    <p className="Text m-0">Open Repairs</p>
                  </div>
                  <div className="Cards d-flex flex-column justify-content-center align-items-center px-3 py-3">
                    <h1 className="Value m-0">{omc}</h1>
                    <p className="Text m-0">Regular Maintenance</p>
                  </div>
                </div>
              </CardContainer>
            </div>
            <div
              className="col-12 col-md-6 col-lg-4 p-0 ps-lg-2 pe-0  "
              id="rowcolumns"
            >
              <CardContainer className={"PendingReports w-100 h-100"}>
                <h1 className="Heading m-0">Pending Reports</h1>
                <div className="d-flex flex-row OpenRepairRegularMaintenanceContent">
                  <div className="Cards d-flex flex-column justify-content-center align-items-center px-2 py-3">
                    <h1 className="Value m-0">{ngas}</h1>
                    <p className="Text m-0">Gas Safety</p>
                  </div>
                  <div className="Cards d-flex flex-column justify-content-center align-items-center px-2 py-3">
                    <h1 className="Value m-0">{nepc}</h1>
                    <p className="Text m-0">EPC</p>
                  </div>
                  <div className="Cards d-flex flex-column justify-content-center align-items-center px-2 py-3">
                    <h1 className="Value m-0">{necir}</h1>
                    <p className="Text m-0">EICR</p>
                  </div>
                </div>
              </CardContainer>
            </div>
            <div className="col-12 p-0">
              <MainTenanceSchedule />
              <AllTablesSection
                pageSize={tableSize}
                data={data}
                tableSize={searchData == "" ? tableSize : SearchTableDataSize}
                setSearchData={setSearchData}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                onPageChange={fetchData}
                setSortModalOpen={setSortModalOpen}
                setFilterModalOpen={setFilterModalOpen}
                url={url}
                setUrl={setUrl}
                totalDataCount={totalDataCount}
              >
                <PropertyTable data={tableData} />
              </AllTablesSection>
            </div>
          </div>
        </div>
      </div>
      {sortModalOpen && (
        <SortModal
          setSortModalOpen={setSortModalOpen}
          sortOption={sortOption}
          setSortOption={setSortOption}
          handleSortChange={handleSortChange}
          handleApplySort={handleApplySort}
          options={optionToSort}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          setFilterModalOpen={setFilterModalOpen}
          handleApplyFilter={handleApplyFilter}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          handleClearFilter={handleClearFilter}
          options={optionToFilter}
        />
      )}
    </>
  );
}
