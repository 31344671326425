import styled from 'styled-components';
export const ProfileContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f8;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
    font-size: 24px;
    color: #21296D;
    text-align: center;
    margin-bottom: 20px;
    grid-column: span 2;
    `;

export const ProfileField = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

`;

export const Label = styled.label`
    font-size: 16px;
    color: #21296D;
    margin-bottom: 4px;
`;

export const Value = styled.p`
    font-size: 14px;
    color: #333;
    margin: 0;
`;

export const Input = styled.input`
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

export const Textarea = styled.textarea`
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 100px;
    resize: vertical;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const EditButton = styled.button`
    background-color: #21296D;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #1a237e;
    }
`;

export const SaveButton = styled(EditButton)`
    background-color: #4CAF50;
    &:hover {
        background-color: #45a049;
    }
`;
export const ProfilePhoto = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
    grid-column: span 2;
    margin: 10px auto;
`;