// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

const firebaseConfig = {
  apiKey: "AIzaSyA8TXrB8cO7WC5Cu05unlMoPjrpA3ki-V8",
  authDomain: "scorify-67a21.firebaseapp.com",
  projectId: "scorify-67a21",
  storageBucket: "scorify-67a21.appspot.com",
  messagingSenderId: "785131124457",
  appId: "1:785131124457:web:3ea94e89a72a7149a92278",
  measurementId: "G-Y8QWNNPVH3"
};

// Initialize Firebase
export const fClient = initializeApp(firebaseConfig);
export const fDatabase = getFirestore(fClient);