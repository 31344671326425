import { React, useContext } from "react";
import "../Common/InspectionInventory.css";
import { MainContext } from "../../Context/MainContext";
import ViewButton from "../../../StructuredComponents/global/table/ViewButton";
import DownloadButton from "../../../StructuredComponents/global/table/DownloadButton";
import { addressToPdfName } from "../../../lib/helper/global/reports";
export default function RECIRRows({ data }) {
  const { dispatch } = useContext(MainContext);
 
 
  const color = (col) => {
    switch (col) {
      case "satisfactory":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };

      default:
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
    }
  };
  return (
    <>
      <tr className="w-100 d-flex flex-row">
        <td
          className="RowItm d-flex flex-column justify-content-center align-items-start"
          style={{ width: "40%" }}
        >
          {
            <span
              onClick={() => {
                dispatch({
                  type: "popup",
                  payload: {
                    popupData: data,
                    popupStae: true,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >{` ${data.PropertiesAdderess}`}</span>
          }
        </td>

        <td className="RowItm" style={{ width: "20%" }}>
          <span
            className="ChipTenancy"
            style={{
              ...color(data.CurrentRating),
              textTransform: "capitalize",
            }}
          >{` ${data.CurrentRating}`}</span>
        </td>
        <td
          className="RowItm d-flex flex-column justify-content-center align-items-start"
          style={{ width: "20%" }}
        >
          {` ${data.Date}`}
        </td>
        <td
          className="RowItm d-flex flex-row justify-content-center align-items-center gap-2"
          style={{
            width: "20%",
          }}
        >
          <ViewButton
            Documenturl={data.documenturl}
          />
          <DownloadButton
            Documenturl={data.documenturl}
            filename={addressToPdfName(data.filename)}
          />
        </td>
      </tr>
    </>
  );
}
