import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileField,
  Title,
  ButtonContainer,
  Label,
  Input,
  SaveButton,
  EditButton,
  Value,
  ProfilePhoto,
} from "./Profile.style";
import api from "../../../api";
import { useParams } from "react-router-dom";

const placeholderImage = "/profile.png"; // URL of the placeholder image

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    phone: "",
    email: "",
    profile_photo_url: "",
    location: "",
    website: "",
  });
  const propertyId = useParams()?.propertyId;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const getProfileData = async () => {
    try {
      const response = await api.get(`/property/tenant-profile/${propertyId}`);
      setProfileData(response.data); // Update the profileData state with fetched data
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImage;
  };

  return (
    <ProfileContainer>
      <Title>Profile</Title>
      <ProfilePhoto
        src={profileData.profile_pic||placeholderImage}
        alt="Profile"
        onError={handleImageError}
      />
      <ProfileField>
        <Label>Name:</Label>
        {isEditing ? (
          <Input
            type="text"
            name="name"
            value={profileData.name}
            onChange={handleChange}
          />
        ) : (
          <Value>{profileData.name|| "N/A"}</Value>
        )}
      </ProfileField>
      <ProfileField>
        <Label>Phone:</Label>
        {isEditing ? (
          <Input
            type="text"
            name="phone"
            value={profileData.phone}
            onChange={handleChange}
          />
        ) : (
          <Value>{profileData.phone || "N/A"}</Value>
        )}
      </ProfileField>
      <ProfileField>
        <Label>Email:</Label>
        <Value>{profileData.email|| "N/A"}</Value>
      </ProfileField>
      {/* <ProfileField>
        <Label>Location:</Label>
        {isEditing ? (
          <Input
            type="text"
            name="location"
            value={profileData.location}
            onChange={handleChange}
          />
        ) : (
          <Value>{profileData.location || "N/A"}</Value>
        )}
      </ProfileField>
      <ProfileField>
        <Label>Website:</Label>
        {isEditing ? (
          <Input
            type="text"
            name="website"
            value={profileData.website}
            onChange={handleChange}
          />
        ) : (
          <Value>
            <a
              href={`https://${profileData.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {profileData.website|| "N/A"}
            </a>
          </Value>
        )}
      </ProfileField> */}
      {/* <ButtonContainer>
                {isEditing ? (
                    <SaveButton onClick={toggleEdit}>Save</SaveButton>
                ) : (
                    <EditButton onClick={toggleEdit}>Edit Profile</EditButton>
                )}
            </ButtonContainer> */}
    </ProfileContainer>
  );
};

export default Profile;
