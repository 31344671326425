import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PropertytimeLineBodyContent from "./AgentComponents/PropertyTimeLine/PropertytimeLineBodyContent";
import Dashboard from "./AgentComponents/Dashboard/Dashboard";
import { MianProvider } from "./Context/MainContext";
import Properties from "./AgentComponents/Properties/Properties";
import MaintenanceandRepair from "./AgentComponents/MaintenanceandRepair/MaintenanceandRepair";
import ReportMainContent from "./AgentComponents/Report/ReportsMainContent";
import Inspection from "./AgentComponents/Inspection/Inspection";
import ReportEPCTableContainer from "./AgentComponents/Report/ReportEPCTableContainer";
import RTISITableContainer from "./AgentComponents/Report/RTSITableContainer";
import RCheckOutTableContainer from "./AgentComponents/Report/RCheckOutTableContainer";
import RGasSafetyTableContainer from "./AgentComponents/Report/RGasSafetyTableContainer";
import RInventoryTableContainer from "./AgentComponents/Report/RInventoryTableContainer";
import Calendar from "./AgentComponents/Calendar/Calendar";
import AgentMainDashBoard from "./MainDashBord/AgentMainDashBoard";
import LoginPage from "./AuthNew/Auth/LoginPage";
import OtpScreen from "./AuthNew/Auth/OtpScreen";

import ForgotPasswordPage from "./AuthNew/Auth/ForgotPasswordPage";
import PrivateRoute from "./PrivateRoute";
import SignupPage from "./AuthNew/Auth/SignupPage";
import Invite from "./AgentComponents/Invite";
import DocumentUpload from "./AgentComponents/Upload/DocumentUpload";
import SetPasswordPage from "./AuthNew/Auth/SetPasswordPage";
import PasswordSetSuccessful from "./AuthNew/Auth/PasswordSetSuccessful";
import { AgentprivateRoute } from "./PrivateRoute";
import Error from "../Error";
import ReportECIRTableContainer from "./AgentComponents/Report/ReportECIRTableContainer";
import ContactSupport from "./pages/ContactUs/ContactUs";
import Profile from "./pages/Profile/Profile";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import AgentsProfileSection from "../components/AgentsProfileSection";
export default function MainAgentRoute() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <AgentprivateRoute element={<Navigate to={"/agent/dashboard"} />} />
          }
        />
        <Route
          path="signup"
          element={
            <MianProvider>
              <SignupPage />
            </MianProvider>
          }
        />
        <Route
          path="verifyotp"
          element={
            <MianProvider>
              <OtpScreen />
            </MianProvider>
          }
        />
        <Route
          path="dashboard"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <Dashboard />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="propertytimeline"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <PropertytimeLineBodyContent />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="calendar"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <Calendar />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="invite"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <Invite />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="upload"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <DocumentUpload />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="property"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <Properties />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="maintenance"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <MaintenanceandRepair />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <ReportMainContent />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/epc"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <ReportEPCTableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/tenantself-inspections"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <RTISITableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/inventory"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <RInventoryTableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/ecir"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <ReportECIRTableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/check-out"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <RCheckOutTableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="reports/gassafetycertificate"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <RGasSafetyTableContainer />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="inspections"
          element={
            <AgentprivateRoute
              element={
                <AgentMainDashBoard>
                  <RTISITableContainer viewCards />
                </AgentMainDashBoard>
              }
            />
          }
        />

        <Route
          path="/contact-us"
          element={
            <PrivateRoute
              element={
                <AgentMainDashBoard>
                  <ContactSupport />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute
              element={
                <AgentMainDashBoard>
                  <AgentsProfileSection />
                </AgentMainDashBoard>
              }
            />
          }
        />
        <Route
          path="/coming-soon"
          element={
            <PrivateRoute
              element={
                <AgentMainDashBoard>
                  <ComingSoon />
                </AgentMainDashBoard>
              }
            />
          }
        />
      </Routes>
    </>
  );
}
