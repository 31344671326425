import { React, useState, useContext, useEffect } from "react";
import "./InspectionInventory.css";
import Tabs from "./Tabs";
import { MainContext } from "../Context/MainContext";
import InspectionTable from "./InspectionTable";
import DocumentViewerComp from "./DocumentViewerComp.js";
import api from "../api.js";
import DataSpinner from "./Spinner/DataSpinner.js";
import { useParams } from "react-router-dom";
import { transformMainInspectionData } from "../lib/helper/property/inspectionHelper.js";
export default function InspectionInventory(props) {
  const [pageStart, setPageStart] = useState(0);
  const { docView, dispatch } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const params = useParams();
  const propertyId = params?.propertyId;
  function convertDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await api.get("landlord/dashboard/");
        const pid = res.data.properties[0].property.id;
        const response = await api.get(`landlord/inventory-inspection/${pid}/`);
        if (response.status == 200) {
          setLoading(false);
        }
        const fetchedData = response.data.data.map((item, index) => ({
          document: {
            id: index + 1,
            type: item.document_type,
            title: item.title,
            file: item.document,
          },
          cretor: {
            name: item?.created_by?.name,
            profile_photo: item?.created_by?.profile_photo,
          },
          date: convertDate(item.date),
          expiary_date: convertDate(item.expiry_date),
        }));
        setTableData(fetchedData);
        setLoading(false);
      } catch (err) {
        console.log("Error while fetching the data", err);
      }
    };
    const fetchInspectionData = async () => {
      try {
        const response = await api.get(`property/inspections/${propertyId}`);
        setMainTableData(transformMainInspectionData(response.data?.data?.results));
        console.log(response.data?.data?.results);
      } catch (error) {
        console.log("Error while fetching the data", error);
      }
    };
    fetchData();
    fetchInspectionData();
  }, []);

  const tableSize = tableData.length;


 

  return (
    <>
      {/* {loading && <DataSpinner/>} */}
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="InspectioInventorySectionContainer">
              {docView ? (
                <DocumentViewerComp />
              ) : (
                <div className="w-100 h-100 p-0 m-0 d-flex flex-column gap-3">
                  {/* <Tabs /> */}
                  {true ? (
                    <InspectionTable data={mainTableData} />
                  ) : (
                    <div className="NoDataContainer">
                      <p>No Data Available</p>
                    </div>
                  )}
                  <div className="ButtonGroupContainer d-flex flex-row justify-content-end align-items-center gap-2">
                    <button
                      onClick={() => {
                        setPageStart((prev) => Math.max(0, prev - 10));
                      }}
                      className="BtnPrevious d-flex flex-row justify-content-center align-items-center"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
                          stroke="#18181B"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="btnText m-0">Previous</p>
                    </button>
                    <button
                      onClick={() => {
                        setPageStart((prev) =>
                          prev + 10 >= tableSize ? prev : prev + 10
                        );
                      }}
                      className="BtnNext d-flex flex-row justify-content-center align-items-center"
                    >
                      <p className="btnText m-0">Next</p>
                      <svg
                        className="nxtIconSvg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
                          stroke="#18181B"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
