import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled Components
const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

const Content = styled.div`
  padding: 20px;
  text-align: center;
`;

const LoadingPage = ({ loading }) => {
  return (
    <div>
      {loading && (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
    </div>
  );
};

export default LoadingPage;
