import React, { useContext, useEffect, useState } from "react";
import "./FloarMapPhotos.css";
import FMPSGalleryComp from "./FMPSGalleryComp";
import { Link, useParams } from "react-router-dom";
import { MainContext } from "../Context/MainContext";
import api from "../api";
import { transformFloorPlanData } from "../lib/helper/property/floorplanHelper";
import LoadingPage from "./Spinner/Loader";

export default function FloarMapPhotosSection() {
  const [roomData, setRoomData] = useState({});
  const params = useParams();
  const propertyId = params?.propertyId;
  const [roomsData, setRoomsData] = useState([]);
  const [loading, setLoading] = useState(false);

  function capitalizeFirstLetter(word) {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await api.get("landlord/dashboard/");
        const property_id = response1.data.properties[0].property.id;
        const response = await api.get(`landlord/house-photo/${property_id}/`);
        const houseItems = response.data;
        const formattedData = {};
        houseItems.forEach((item) => {
          const itemType = item.item_type.toLowerCase().replace(" ", "");
          if (!formattedData[itemType]) {
            formattedData[itemType] = [];
          }

          const itemData = item.house_item.map((photo) => ({
            id: photo.id,
            image: photo.image,
            status: photo.status,
          }));

          formattedData[itemType] = [...formattedData[itemType], ...itemData];
        });

        setRoomData(formattedData);
      } catch (err) {
        console.log("Error while fetching data", err);
      }
    };
    const fetchImageDatas = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `/property/aggregated-house-photos/${propertyId}`
        );
        const data = transformFloorPlanData(response.data || {}, propertyId);
        setRoomsData(data);
      } catch (error) {
        console.log("Error while fetching data", error);
        setRoomsData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchImageDatas();
  }, []);

  const { dispatch } = useContext(MainContext);

  

  return (
    <div className="FloarMapPhotosSection">
      <div className="Container-fluid p-0">
        <div className="row m-0">
          {roomsData.map((room, index, innerArray) => {
            return (
              <div
                key={index}
                className="col-12 col-sm-2 col-md-4 col-lg-3 p-2"
              >
                <Link
                  to={room.path}
                  
                  onClick={() => {
                    dispatch({
                      type: "path",
                      payload: {
                        path: `Dashboard Floar Map And Photo ${room.type}`,
                        sidebar: false,
                      },
                    });
                  }}
                  style={{
                    textDecoration: "none",
                    padding: "0px",
                    width: "calc(100%)",
                    font: "none",
                  }}
                >
                  <FMPSGalleryComp
                    title={capitalizeFirstLetter(room.type)}
                    data={room.images}
                    index={index}
                    remainingImages={room.remainingImagesCount}
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <LoadingPage loading={loading} />
    </div>
  );
}
