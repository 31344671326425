import React, { useState } from "react";
import {
  FormContainer,
  Input,
  Label,
  SubmitButton,
  Textarea,
} from "./ContactUs.style";
import api from "../../../api";
import { toast } from "react-toastify";

const ContactSupport = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await api.post("/agent/support/", { email, message });

      toast.success("Message sent successfully!");
      setEmail("");
      setMessage("");
      setName("");
    } catch (error) {
      toast.warn("Failed to send the message.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      {/* <Label>Name:</Label>
      <Input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <Label>Email:</Label>
      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      /> */}

      <Label>Message:</Label>
      <Textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />

      <SubmitButton type="submit" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
      </SubmitButton>
    </FormContainer>
  );
};

export default ContactSupport;
