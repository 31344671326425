import React from "react";
import "./SideBarCompStyle.css";
import BandComp from "./BrandComp";
import AgentNavBarComp from "./AgentNavBarComp";
export default function AgentSideBarcomp() {
  return (
    <>
      <div className="SideBarContainer">
        <BandComp></BandComp>
        <AgentNavBarComp />
      </div>
    </>
  );
}
