import {React,useMemo} from "react";
import '../Common/InspectionInventory.css';
import './Report.css';
import PropertyTableContainer from "../Properties/PropertyTableContainer";
import RagsSaftyRow from "./RgasSaftyRow";
import styles from '../../../modulecss/agents/reports/GasSafety.module.css'
export default function RGSTable({data,children}){
    const columns = useMemo(
        ()=>[
            {
                Header:"Properties Address",
                className:`ASIPropertiesAddressCol ${styles?.property_address_col}`,
                accessor: "document.id"
            },
          
            {
                Header:"Result",
                className:`ASIResultCol ${styles?.result_col}`,
                accessor: "document.type"
            },
            {
                Header:"Date",
                className:`ASICreateByCol ${styles?.date_col}`,
                accessor: "document.title"
            },
            {
                Header:"Expiry Date",
                className:`ASIDateCol ${styles?.expiry_col}`,
                accessor: "cretor.name"
            },
            {
                Header:"Action",
                className:`ASIActionCol ${styles?.action_col}`,
                accessor: "expiry_date"
            }
        ],[]);
    return(
        <>
            <div className="AgentSelfInspectionTableContainer">
                <PropertyTableContainer columns={columns} data={data} tablename={'ATSItable'} >
                    <RagsSaftyRow/>
                </PropertyTableContainer>
            </div>
        </>
    );
}
