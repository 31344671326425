import React, { useState, useEffect } from "react";
import "./RegularMaintainens.css";
import MaintenanceSchedularItm from "./MaintenanceSchedularItm";
import api from "../api";
import { useParams } from "react-router-dom";
import { transformRegularMaintainanceData } from "../lib/helper/property/regularMaintainance";
import LoadingPage from "./Spinner/Loader";
import UploadMaintainancePdf from "./Property/global/UploadMaintainancePdf";

export default function RMMaintenanceSchedule({ setCurrentScore }) {
  const [data, setData] = useState([]);
  const [isdata, setIsData] = useState("");
  const [loading, setLoading] = useState(false);
  const propertyId = useParams()?.propertyId;
  const [currentTaskId, setCurrentTaskId] = useState(-1);
  const handleOpenCurrentTask = (taskId) => {
    setCurrentTaskId(taskId);
  };
  const handleCloseCurrentTask = () => {
    setCurrentTaskId(-1);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/property/maintenance-schedule/${propertyId}`
      );
      setData(transformRegularMaintainanceData(response.data));
      setCurrentScore(response.data.overall_maintenance_score);
    } catch (err) {
      setIsData("No Data Available");
      console.log("Error while fetching the data", err);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const day = date.getDate();
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = date.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };
  
  return (
    <div className="w-100 h-100 RMMaintenanceSchedule d-flex flex-column justify-content-start p-2">
      {data.map((item, index) => (
        <MaintenanceSchedularItm
          id={item?.taskId}
          key={index}
          statusval={item?.status}
          countval={item?.score > 0 ? 1 : 0}
          title={item.service}
          reported={item.type}
          description={item.description}
          due_date={formatDate(item.dueDate)}
          report_photos={item.image}
          handleOpenCurrentTask={handleOpenCurrentTask}
        />
      ))}
      <LoadingPage loading={loading} />
      <UploadMaintainancePdf
        handleCloseStartTaskModal={handleCloseCurrentTask}
        selectedTaskId={currentTaskId}
        isOpen={currentTaskId !== -1}
        handleReFetch={fetchData}
      />
    </div>
  );
}
