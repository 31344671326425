import { React, useContext, useState } from "react";
import "../Common/InspectionInventory.css";
import RadialGauge from "../GuageEle";
import MaintainanceTimeline from "../MaintainanceTimeline/MaintainanceTimeline";
import { MainContext } from "../../Context/MainContext";
export default function RMTableRows({ data }) {
  const { dispatch } = useContext(MainContext);
  return (
    <>
      <tr className="w-100 d-flex flex-row" style={{ minHeight: "60px" }}>
        <td
          className="RowItm RmRow d-flex flex-column justify-content-center align-items-start"
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch({
              type: "popup",
              payload: {
                popupId: data.id,
                popupStae: true,
              },
            });
          }}
        >
          {<span>{` ${data.PropertiesAdderess}`}</span>}
        </td>
        {/* <td className="RowItm RmRow">
                {` ${data.FloorPlan}`}
            </td> */}
        <td className="RowItm RmRow d-flex flex-column justify-content-center align-items-start">{` ${data.Description}`}</td>

        <td className="RowItm RmRow d-flex flex-column justify-content-center align-items-start">{` ${data.Title}`}</td>
        {/* <td className="RowItm RmRow" >
          <RadialGauge value={data.PropScore} lable={"Good"} />
        </td> */}
        <td className="RowItm RmRow d-flex flex-column justify-content-center align-items-start">{` ${data.Status}`}</td>
      </tr>
    </>
  );
}
