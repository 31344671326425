import React, { useEffect, useRef, useState } from "react";
import api from "../../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingPage from "../../Spinner/Loader";

const options = [
  { label: "Building Report", value: "open" },
  { label: "Arranging Engineer", value: "pending" },
  { label: "Report Completion", value: "completed" },
];
const StartTaskModalProperty = ({
  isOpen = false,
  handleCloseStartTaskModal = () => {},
  selectedTaskId,
  handleReFetch,
  currentStatus,
}) => {
  const [startTaskOption, setStartTaskOption] = React.useState(
    options.find((option) => option.value === currentStatus)?.value || options[0].value
  );
  const [laoding, setLaoding] = useState(false);
  const modalRef=useRef(null);

  const handleStartTaskChange = (e) => {
    setStartTaskOption(e.target.value);
  };

  const handleStartTask = async () => {
    try {
      setLaoding(true);
      const response = await api.put(
        `/agent/repair-status-update/${selectedTaskId}/`,
        {
          status: startTaskOption,
          id: selectedTaskId,
        }
      );
      toast.success("Task updated successfully");
    } catch (error) {
      toast.error("Failed to start the task");
    } finally {
      setLaoding(false);
      handleCloseStartTaskModal();
      handleReFetch();
    }
  };
  const handleScroll = (e) => {
    if (modalRef.current) {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
  
      // Calculate the modal dimensions
      const modalHeight = modalRef.current.offsetHeight;
      const modalWidth = modalRef.current.offsetWidth;
  
      // Get the computed transform scale of .app-container
      const appContainer = document.querySelector('.app-container');
      const transformStyle = window.getComputedStyle(appContainer).transform;
      
      // Check if scale is applied (and less than 1)
      let scale = 1;
      if (transformStyle !== 'none') {
        const matrix = transformStyle.match(/matrix\(([^)]+)\)/);
        if (matrix) {
          scale = parseFloat(matrix[1].split(', ')[0]); // Extract the scale value from the matrix
        }
      }
  
      // Conditionally calculate an extra offset only if scale is less than 1
      const extraOffset = scale < 1 ? (scrollY / 100 * 10) : 0; // Modify the multiplier as needed
  
      // Calculate the center position with conditional offset
      const topPosition = scrollY + (viewportHeight - modalHeight) / 2 + extraOffset;
      const leftPosition = (viewportWidth - modalWidth) / 2;
  
      // Set the modal position
      modalRef.current.style.top = `${topPosition}px`;
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      document.getElementById("root").style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      handleScroll();
    } else {
      document.getElementById("root").style.backgroundColor = "#f4f4f5";
    }
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      document.getElementById("root").style.backgroundColor = "#f4f4f5";
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);
  
  return isOpen ? (
    <>
      <div className="modal-backdrop fade show custom-backdrop"></div>
      <div className="modal fade show d-block custom-modal" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Start Task</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseStartTaskModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <label className="form-label">Select Status</label>
                <select
                  className="form-select"
                  value={startTaskOption}
                  onChange={(e) => handleStartTaskChange(e)}
                >
                  <option value="">Please Select an Option</option>
                  {options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => handleCloseStartTaskModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-warning"
                onClick={() => setStartTaskOption("")}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleStartTask}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <LoadingPage loading={laoding} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default StartTaskModalProperty;
