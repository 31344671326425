import { React, useState, useEffect } from "react";
import "./RegularMaintainens.css";
import "./OpenRepairsSection.css";
import "./InspectionInventory.css";
import ORTableRow from "./ORTableRow";
import api from "../api";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingPage from "./Spinner/Loader";
import StartTaskModalProperty from "./Property/global/StartTaskModalProperty";
import { limit } from "firebase/firestore";
export default function ORTableContainer({
  columns,
  name,
  status,
  setRepairScore,
}) {
  const [pageStart, setPageStart] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tableSize, setTableSize] = useState(0);
  const [isdata, setIsData] = useState("");
  const [laodingData, setLaodingData] = useState(false);
  const [isStartTaskModalOpen, setIsStartTaskModalOpen] = useState(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = parseInt(searchParams.get("page") || "1");
  const handleOpenStartTask = (index) => {
    setIsStartTaskModalOpen(index);
  };
  const handleCloseStartTask = () => setIsStartTaskModalOpen(-1);

  const params = useParams();
  const propertyId = params?.propertyId;
  function formatDate(dateString) {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB");
    return formattedDate;
  }
  const fetchData = async (pageNo = 1) => {
    try {
      setLaodingData(true);
      const response = await api.get(`property/repairs/${propertyId}`, {
        params: {
          status,
          limit: 10,
          page: pageNo,
        },
      });
      if (response.status === 200) {
        const item = response.data?.data?.results;
        const transformData = item.map((val) => ({
          title: val.title,
          details: val.description,
          date: formatDate(val.completion_date),
          createdAt: formatDate(val.created_at),
          status: val.status,
          CompletionReport: val.description,
          amount: `£ ${val.cost.toFixed(2)}`,
          report: val.completion_report,
          id: val.id,
        }));

        setTableData(transformData);

        setTableSize(response.data?.data?.count);

        setRepairScore(response.data?.total_repair_score);
        if (!transformData?.length) {
          setIsData("No Data Available");
        }
      } else if (response.status === 404) {
        setIsData("No Data Available");
      } else {
        setIsData("No Data Available");
      }
    } catch (err) {
      setIsData("No Data Available");
      console.log("Error while fetching data", err);
    } finally {
      setLaodingData(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [status]);

  const data = (start, range) => {
    if (range <= 0) {
      return tableData.slice(0, 10);
    } else if (range > tableSize) {
      return tableData.slice(start, tableSize);
    } else {
      return tableData.slice(start, range);
    }
  };

  return (
    <>
      <div
        className="ORTableContainer"
        style={{
          overflow: "scroll",
        }}
      >
        <table className="ORTable">
          <thead>
            <tr className="w-100 d-flex flex-row">
              {columns.map((itm, i) => (
                <th key={i} className={`${itm.className} tableCol`}>
                  {itm.Header}
                  {i < 3 && (
                    <span className="Icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.1041 11.5625L9.99992 16.6667L4.89575 11.5625"
                          fill="#D4D4D8"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.89567 8.4375L9.99984 3.33333L15.104 8.4375"
                          fill="#D4D4D8"
                        />
                      </svg>
                    </span>
                  )}
                </th>
              ))}
              <th className="ActionColumn tableCol">Action</th>
            </tr>
          </thead>
          <tbody>
            {false ? (
              <tr className="w-100 d-flex flex-row">
                <td className="noDataCell" colSpan={columns.length + 1}>
                  {isdata}
                </td>
              </tr>
            ) : (
              tableData.map((itm, i) => (
                <ORTableRow
                  key={i}
                  data={itm}
                  column={columns}
                  name={name}
                  handleOpenStartTask={handleOpenStartTask}
                  status={status}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="ButtonGroupContainer ORTableBtn d-flex flex-row justify-content-end align-items-center gap-2">
        <button
          disabled={pageNo === 1}
          onClick={() => {
            setSearchParams({ page: pageNo - 1 });
            fetchData(pageNo - 1);
          }}
          className="BtnPrevious d-flex flex-row justify-content-center align-items-center"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="btnText m-0">Previous</p>
        </button>
        <button
          disabled={pageNo === Math.ceil(tableSize / 10)}
          onClick={() => {
            setSearchParams({ page: pageNo + 1 });
            fetchData(pageNo + 1);
          }}
          className="BtnNext d-flex flex-row justify-content-center align-items-center"
        >
          <p className="btnText m-0">Next</p>
          <svg
            className="nxtIconSvg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9165 15.8334L7.08317 10.0001L12.9165 4.16675"
              stroke="#18181B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <LoadingPage loading={laodingData} />
        <StartTaskModalProperty
          isOpen={isStartTaskModalOpen !== -1}
          handleCloseStartTaskModal={handleCloseStartTask}
          selectedTaskId={isStartTaskModalOpen}
          handleReFetch={fetchData}
          currentStatus={status}
        />
      </div>
    </>
  );
}
