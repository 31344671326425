import React, { useEffect, useState } from "react";
import "../MaintainanceTimeline/MaintainanceTimeline.css";
import img from "./Avatar.png";

const PropertyTimeline = ({ timelineEvents, className }) => {
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month}, ${year}`;
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index); // Set the hovered index
  const handleMouseLeave = () => setHoveredIndex(null);

  const satus = ["c", "c", "c", "c", "c", "c", "c"];
  return (
    <div
      className={`timeline-container PropertyTimeLineContainer ${className} mb-2`}
    >
      {timelineEvents.map((event, index) => (
        <div
          key={index}
          className="timeline-item d-flex flex-row align-items-center"
          style={{ zIndex: 1000 - index }}
        >
          <div className="timeline-date" style={{ width: "120px" }}>
            {formatDateToCustom(event.created_at)}
          </div>
          <div
            className="ImgContainer d-flex flex-column justify-content-center align-items-center"
            style={{
              position: "relative",
              backgroundColor:
                event?.status == "completed" ? "#F0FDF4" : "#FAFAFA",
              borderColor: event?.status == "completed" ? "#22C55E" : "#D4D4D8",
            }}
          >
            <div
              className="TimeLine"
              style={{
                borderStyle: event?.status == "completed" ? "solid" : "dashed",
                borderColor:
                  event?.status == "completed" ? "#22C55E" : "#D4D4D8",
              }}
            ></div>
            {event?.status == "completed" ? (
              <svg
                width="11"
                height="9"
                viewBox="0 0 11 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.861816 4.31195L3.82932 7.2782L9.76182 1.3457"
                  stroke="#22C55E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : null}
          </div>
          <div className="timeline-content" style={{ position: "relative" }}>
            <div
              className="timeline-text ellipsis-text"
              onMouseEnter={() => handleMouseEnter(index)} // Pass the index to identify the hovered item
              onMouseLeave={handleMouseLeave}
            >
              {event.description}
              {hoveredIndex === index && (
                <div className="tooltip">{event.description}</div>
              )}
            </div>
            <div className="timeline-person">
              <img
                src={event?.profile_photo || img}
                alt={event?.profile_photo}
              />
              <div
                className={`timeline-role person-role ${
                  event.user_type === "Agent" ? "agent" : "tenant"
                }`}
              >
                {event.user_type}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyTimeline;
