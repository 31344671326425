import { React, useState, useContext, useEffect } from "react";
import { MainContext } from "../../Context/MainContext";
import api from "../../api";
import Modal from "../MaintainanceTimeline/Model";
import PropertyDetails from "../MaintainanceTimeline/PropertyDetails";
import Communication from "../../../AgentComponents/MaintainanceTimeline/Communication";
import "./Properties.css";
import PropertyTimeline from "./PropertyTimeline";
import { useNavigate } from "react-router-dom";
const MainTenanceSchedule = () => {
  const { popupStae, dispatch, popupId } = useContext(MainContext);
  const [timelinedata, SetTimelinedata] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [communicationData, setCommunicationData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`agent/property-timeline/${popupId}/`);
        SetTimelinedata(response?.data?.timeline_data);
        setPropertyDetails(response?.data?.property_details);
        setCommunicationData(response?.data?.communication_data);
      } catch (err) {
        console.log("Error while the data", err);
      }
    };
    if (popupId) {
      fetchData();
    }
  }, [popupId]);

  const people = [
    {
      name: "Khadijah Ali",
      role: "Landlord",
      image: "path-to-khadijah-image",
      icons: {
        person: "path-to-person-icon",
        call: "path-to-call-icon",
        message: "path-to-message-icon",
      },
    },
    {
      name: "Sara Jane",
      role: "Tenant",
      image: "path-to-sara-image",
      icons: {
        person: "path-to-person-icon",
        call: "path-to-call-icon",
        message: "path-to-message-icon",
      },
    },
  ];

  const timelineEvents = [
    {
      created_at: "JUL 24, 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "Khadijah Ali",
        role: "Agent",
        roleClass: "landlord",
        image: "path-to-person-image",
      },
    },
    {
      created_at: "JUL 25, 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.",
      person: {
        name: "Sara Jane",
        role: "Tenant",
        roleClass: "tenant",
        image: "path-to-person-image",
      },
    },
  ];
  const [open, setOpen] = useState(popupStae);
  useEffect(() => {
    setOpen(popupStae);
  }, [popupStae]);

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "popup",
      payload: {
        popupData: {},
        popupStae: false,
      },
    });
  };

  return (
    <div className={`${open ? "modal-shadow" : ""}`}>
      <Modal isOpen={open} onClose={handleClose}>
        <div className="mb-4">
          {propertyDetails && (
            <h5 className="heading-text">Property Details</h5>
          )}
          <PropertyDetails data={propertyDetails} />
          {communicationData.length > 0 && (
            <h5 className="heading-text">Communication</h5>
          )}
          <Communication people={communicationData} />
          {timelinedata.length > 0 && (
            <h5 className="heading-text">Property Timeline</h5>
          )}
          <PropertyTimeline
            className={"MTSechular"}
            timelineEvents={timelinedata}
          />
          <div className="prop-btn">
            <button
              className="property-button"
              onClick={() => navigate(`/property/${popupId}/dashboard`)}
            >
              Go to Property
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MainTenanceSchedule;
