import { React, useContext } from "react";
import "../Common/InspectionInventory.css";
import { MainContext } from "../../Context/MainContext";
export default function REPCRows({ data }) {
  const { dispatch } = useContext(MainContext);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const downloadFile = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const color = (col) => {
    switch (col) {
      case "A":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "B":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "C":
        return {
          backgroundColor: "#FAFAFA",
          borderColor: "#52525B",
          color: "#52525B",
        };
      case "a":
        return {
          backgroundColor: "#F0FDF4",
          borderColor: "#16A34A",
          color: "#16A34A",
        };
      case "b":
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
      case "c":
        return {
          backgroundColor: "#FAFAFA",
          borderColor: "#52525B",
          color: "#52525B",
        };
      case "Arranging Access":
        return {
          backgroundColor: "#EDF4FF",
          borderColor: "#5A74FA",
          color: "#5A74FA",
        };
      default:
        return {
          backgroundColor: "#FEF2F2",
          borderColor: "#EF4444",
          color: "#EF4444",
        };
    }
  };
  return (
    <>
      <tr className="w-100 d-flex flex-row">
        <td
          className="RowItm d-flex flex-column justify-content-center align-items-start"
          style={{ width: "40%" }}
        >
          {
            <span
              onClick={() => {
                dispatch({
                  type: "popup",
                  payload: {
                    popupData: data,
                    popupStae: true,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >{` ${data.PropertiesAdderess}`}</span>
          }
        </td>
        <td className="RowItm" style={{ width: "20%" }}>
          <span
            className="ChipTenancy"
            style={color(data.Document)}
          >{` ${data.Document}`}</span>
        </td>
        <td className="RowItm" style={{ width: "20%" }}>
          <span
            className="ChipTenancy"
            style={color(data.CurrentRating)}
          >{` ${data.CurrentRating}`}</span>
        </td>
        <td className="RowItm d-flex flex-column justify-content-center align-items-start" style={{width:"20%"}}>
          {` ${data.Date}`}
        </td>
      </tr>
    </>
  );
}
