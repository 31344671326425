import React, { useEffect, useState } from "react";
import "./MouldandHumidityStyle.css";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
import api from "../api";
import { formatDateToDDMMYYYY, getOneDayBack } from "../lib/helper/global/date";

export default function SMHGraphComp({ details, mainData }) {
  const options = {
    responsive: true,
    elements: {
      point: {
        pointStyle: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 5,
          color: "#09090B",
          font: {
            size: 14,
          },
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          padding: 10,
          color: "#09090B",
          font: {
            size: 14,
          },
        },
        grid: {
          color: "#D4D4D8",
          drawTicks: true,
        },
        border: {
          dash: function (context) {
            if (context.tick.value == 0) {
              return null;
            }
            return [2, 3];
          },
        },
      },
    },
  };
  let labels = mainData?.map((itm) => formatDateToDDMMYYYY(itm?.date)) || [];
  if (labels?.length) {
    const firstData = labels[0];
    const backDay = getOneDayBack(firstData);
    labels.unshift(formatDateToDDMMYYYY(backDay));
  } else {
    labels = [formatDateToDDMMYYYY(getOneDayBack(new Date()))];
  }
  const linefun = (m, c) => {
    let arrayele = [];
    labels.forEach((itm) => {
      let a = m * itm + c;
      arrayele.push(a);
    });
    return arrayele;
  };
  const mouldPresenceData = [0];
  mainData?.forEach((itm) => {
    mouldPresenceData.push(itm?.score);
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Percentage of Mould Presence",
        data: mouldPresenceData,
        backgroundColor: "#5A74FA",
        borderColor: "#5A74FA",
      },
    ],
  };
  return (
    <>
      <div className="SMHGraphContainer d-flex flex-column  gap-4 p-3">
        <h1
          className="Heading m-0"
          style={{ justifyContent: "start", display: "flex" }}
        >
          Mould Presence Over 2 Years with different ventilation
        </h1>
        <Line options={options} data={data} />
        <div className="legendContiner d-flex flex-row justify-content-center gap-2">
          <div className="lablecontainer d-flex flex-row align-items-center gap-1">
            <div className="Ylable d-flex flex-row justify-content-center align-items-center">
              Y
            </div>
            <p className="YlableText m-0">% Mould Presence </p>
          </div>
          <div className="lablecontainer d-flex flex-row align-items-center gap-1">
            <div className="Ylable d-flex flex-row justify-content-center align-items-center">
              X
            </div>
            <p className="YlableText m-0">Recorded Date</p>
          </div>
        </div>
      </div>
    </>
  );
}
